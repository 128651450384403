import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  snackBarRedux: {
    open: false,
    message: "",
    severity: "success",
  },
  alertPopUp: {
    title: "",
    description: "",
    cancelButtonText: "",
    confirmButtonText: "",
    open: false,
    showCloseButton: false,
    showCancelButton: true,
    // cnfFunction: () => {}
  },
};

export const snackbarSclicer = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (state, action) => {
      state.snackBarRedux.open = action.payload.open;
      state.snackBarRedux.message = action.payload.message;
      state.snackBarRedux.severity = action.payload.severity;
    },
    closeSnackbar: (state, action) => {
      state.snackBarRedux.open = false;
      state.snackBarRedux.message = "";
    },
    openAlertPopUp: (state, action) => {
      state.alertPopUp.open = true;
      state.alertPopUp.title = action.payload.title;
      state.alertPopUp.description = action.payload.description;
      state.alertPopUp.cancelButtonText = action.payload.cancelButtonText;
      state.alertPopUp.confirmButtonText = action.payload.confirmButtonText;
      // state.alertPopUp.cnfFunction = action.payload.cnfFunction;
    },
    closeAlertPopUp: (state, action) => {
      state.alertPopUp = initialState.alertPopUp;
    },
  },
});

export const { openSnackbar, closeSnackbar, openAlertPopUp, closeAlertPopUp } = snackbarSclicer.actions;
export default snackbarSclicer.reducer;
