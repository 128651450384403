import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeAlertPopUp, openAlertPopUp } from "../../features/SnackBarslice";

function AlertWindow1() {
    
  const {
    open,
    title,
    description,
    confirmButtonText,
    cancelButtonText,
    cnfFunction,
    
  } = useSelector((state) => state.SnackBarslice.alertPopUp);
  const dispatch = useDispatch();

  const handleClose = function () {
    dispatch(closeAlertPopUp());
  };

  const conformFunction = function () {
    cnfFunction();
    handleClose();
  };
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        ".MuiPaper-root": {
          minWidth: 350,
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          {cancelButtonText}
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={conformFunction}
          autoFocus
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertWindow1;
