import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { VBSkeletonLoader, VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader, VBTableRowNull } from '../../../../Components/ReportTable/ReportTable';
import FilterLable from '../../../../Components/Utils/FilterLable';
import SearchReport from '../../../../Components/Utils/SearchReport';
import FilterBtn from '../../../../Components/Utils/FilterBtn';
import ExportBtn from '../../../../Components/Utils/ExportBtn';
import PrintBtn from '../../../../Components/Utils/PrintBtn';
import Pagenation from '../../../../Components/Utils/Pagenation';
import { CallExpenseSummaryReport } from '../../../../Api/Reports/FinanceApi';
import moment from 'moment';
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from '../../../../Components/Utils/ViknAutoComplete';
import { date_range } from '../../../../Function/comonFunction';
import { CallBranchsList, CallEmployees, CallParties, CallProductCategories, CallProductGroups, CallProductSearch, CallRoutes, CallUsers, CallWarehouses } from '../../../../Api/Reports/CommonReportApi';
import VBDatePiker from '../../../../Components/Utils/VBDatePiker';
import ViknSearchAutoComplete from '../../../../Components/Utils/ViknSearchAutoComplete';
import ViknCheckboxGroup from '../../../../Components/Utils/ViknCheckboxGroup';
import ViknRadioGroup from '../../../../Components/Utils/ViknRadioGroup';
import { callProductCodeSearch, callPurchaseRegister, callSearchAccountLedgers } from '../../../../Api/Reports/PurchaseApi';
import { useDispatch, useSelector } from 'react-redux';
import { Warehouse } from '@mui/icons-material';
import VBInputField from '../../../../Components/Utils/VBInputField';
import { callSalesRegister } from '../../../../Api/Reports/SalesApi';
import { formatNumber } from '../../../../generalFunction';
import { useTranslation } from 'react-i18next';
import { CallBillwiseProfitReport } from '../../../../Api/Reports/AccountApi';
import { Route } from 'react-router-dom';
import { openSnackbar } from '../../../../features/SnackBarslice';
import { VIKNBOOKS_FRONT_URL } from '../../../../generalVeriable';
import ReportDownload from '../../../../Components/Utils/ReportDownload';
import { UserRolePermission } from '../../../../Function/Editions';

const BillwiseProfitReport = () => {
  let Export_view = UserRolePermission("Bill Wise Profit Report", "print_permission");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const dispatch = useDispatch()
  const [reportData, setReportData] = useState({
    data : [],
  })
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [Routes, setRoutes] = useState([0])
  const [Loading, setLoading] = useState(false);
  const [isLoadingRoutes, setIsLoadingRoutes] = useState(false)
  const [routeName, setRouteName] = useState("")
  const [isLoadingParty, setisLoadingParty] = useState(false)
  const [isLoadingSalesman, setIsLoadingSalesman] = useState(false);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,EnableWarehouse,EnableSalesManInSales,EnableRoute, VAT, GST} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const Is_Warehouse = EnableWarehouse
  const Is_SalesMan = EnableSalesManInSales
  const Is_Route = EnableRoute
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const [t, i18n] = useTranslation("common");
  const [filterOptions, setFilterOptions] = useState({
    userList : [],
    UserObj : {customer_name : "" },

    WarehouseList : [],
    WarehouseObj : {WarehouseName :''},

    salesmanList: [],
    salesmanObj: { LedgerName: "" },

    partyList : [],
    partyObj : {PartyName :''},

    RouteList : [],
    routeListValue : [],
    RouteID : [0],

    purchasecost :{
      is_cost_with_tax : {isActive : false}
    },

    filtertype : 'customer',
    filtertypes : [
      {name : t('Customer'),value : 'customer'},
      Is_Route ? { name: t('Route'), value: 'route' } : null,
    ],
    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },
  })
  
  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const searchReport = () => {
    fetchReport();
  }

  const handlechange = (e, name) => {
    let { value } = e.target
    setFilterOptions({
      ...filterOptions,[name]: value,
      partyObj : {PartyName :''},
      routeListValue:[],
    })
  }

  const handleDeletefilter = (name, index) => {
    console.log(name, index);
    if (name === "routeListValue") {
      let routeListValue = [...filterOptions.routeListValue];
      routeListValue = routeListValue.filter((item, idx) => idx !== index);
      setFilterOptions((prevState) => ({
        ...prevState,
        routeListValue: routeListValue,
      }));
    } else {
      setFilterOptions((prevState) => ({ ...prevState, [name]: '' }));
    }
  };
  const handleFilterList = () => {
    let list = [];
    let filterdata = filterOptions
    console.log(filterdata,'>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    if (filterdata.date.FromDate &&filterdata.date.ToDate ) {
      let date = String(filterOptions.date.FromDate.format("DD-MM-YYYY") + " to " + filterOptions.date.ToDate.format("DD-MM-YYYY"))
      list.push({ name: t("Date"), value: date });
    }
    if (filterdata && filterdata.UserObj && filterdata.UserObj.customer_name !== undefined && filterdata.UserObj.customer_name !== ''){
      list.push({
        name: t('User'),
        value: filterdata.UserObj.customer_name,
        handleDelete: () => handleDeletefilter('UserObj')
      })
    }
    if (filterdata && filterdata.WarehouseObj?.WarehouseName !== undefined && filterdata.WarehouseObj.WarehouseName !== '') {
      list.push({
        name : t('Warehouse'),
        value : filterdata.WarehouseObj.WarehouseName,
        handleDelete: () => handleDeletefilter('WarehouseObj')
      })
    }
    if (filterdata.salesmanObj && filterdata.salesmanObj.LedgerName !== undefined && filterdata.salesmanObj.LedgerName !== '') {
      list.push({
          name: t("Salesman"),
          value: filterdata.salesmanObj.LedgerName,
          handleDelete: () => handleDeletefilter("salesmanObj")
      });
    }
    if (filterdata.partyObj && filterdata.partyObj.PartyName !== undefined && filterdata.partyObj.PartyName !== '') {
      list.push({
        name : t('Customer'),
        value : filterdata.partyObj.PartyName,
        handleDelete: () => handleDeletefilter('partyObj')
      })
    }
    if (filterdata.routeListValue && filterdata.routeListValue.length) {
      filterdata.routeListValue.forEach((route, index) => {
        if (route.RouteName !== '' && route.RouteName !== undefined) {
          list.push({
            name: t('Route'),
            value: route.RouteName,
            handleDelete: () => handleDeletefilter('routeListValue',index)
          })
        }
      })
    }
    if (filterdata.purchasecost.is_cost_with_tax.isActive && filterdata.purchasecost.is_cost_with_tax.isActive) {
      list.push({
        name : t('Purchase Cost With Tax'),
        value : 'True'
      })
    }
    return list
  }

  const handleSearchEmployee = async (e, value, name) => {
    if (value) {
      setIsLoadingSalesman(true);
      let salesmanList = await CallEmployees({
        CompanyID:CompanyID,
        BranchID:BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        CreatedUserID:user_id,
        items_per_page: 10,
        page_no: 1,
        search: value,
      });

      let salesman_data = [];
      if (salesmanList.data) {
        salesman_data = salesmanList.data;
      }
      setFilterOptions({ ...filterOptions, salesmanList: salesman_data });
      setIsLoadingSalesman(false);
    } else {
      setFilterOptions({ ...filterOptions, salesmanObj: { LedgerName: "" } });
    }
  };
  //handle change checkbox
  const handleChangeCheckBox = () => {
    setFilterOptions({
      ...filterOptions,
      purchasecost : {
        is_cost_with_tax : {
          isActive : !filterOptions.purchasecost.is_cost_with_tax.isActive
        }
      }
    })
  }
  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      UserObj: { customer_name: "" },
      WarehouseObj : {WarehouseName: ''},
      salesmanObj: { LedgerName: "" },
      
      partyList : [],
      partyObj : {PartyName :''},
      
      filtertype : 'customer',
  
      routeListValue : [],
      RouteID : [0],
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
      purchasecost :{
        is_cost_with_tax : {isActive : false}
      },
  
    })
  }

  // handle search parties 
  const handleSearchParty = async (e,value) => {
    if (value) {
      setisLoadingParty(true);
      const partyResponse = await CallParties({
        CompanyID:CompanyID,
        BranchID:BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        CreatedUserID:user_id,
        PartyType : 1,
        search : value
      })
      let partyList = [];
      if (partyResponse.StatusCode === 6000){
        partyList = partyResponse.data
      }
      setFilterOptions({...filterOptions,partyList})
      setisLoadingParty(false);
    } else {
      // setFilterOptions({...filterOptions,partyObj: {PartyName : ''}});
    }
  }
  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "date") {
        console.log(e, value);
        if (value.name === "Custom") {
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: true,
              value: value,
            },
          });
        } else {
          let date = date_range(value.name);
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: false,
              value: value,
              ToDate: date.todate,
              FromDate: date.fromdate,
            },
          });
        }
      } else {
        setFilterOptions({ ...filterOptions, [name]: value });
      }
    }
  };

const filterApis = async function () {
  setIsLoadingUser(true);
  const userResponse = await CallUsers({
    page_no: 1,
    search: "",
    PartyType: "1",
    CompanyID:CompanyID,
    BranchID:BranchID,
    PriceRounding:Number(PriceDecimalPoint),
  });
  let userList = [];
  if (userResponse?.StatusCode === 6000) {
    userList = userResponse?.data;
  }
  const WarehouseLists = await CallWarehouses ({
    CompanyID:CompanyID,
    BranchID:BranchID,
    PriceRounding:Number(PriceDecimalPoint),
    CreatedUserID:user_id,
    show_all:true
  });
  let WarehouseList = [];
  if (WarehouseLists?.StatusCode === 6000){
    WarehouseList = WarehouseLists?.data;
  }

  setIsLoadingSalesman(true);
  const SalesmanResponse = await CallEmployees({
    CompanyID:CompanyID,
    BranchID:BranchID,
    PriceRounding:Number(PriceDecimalPoint),
    CreatedUserID:user_id,
    items_per_page: 10,
    page_no: 1,
    search: "",
  });
  let salesmanList = [];
  if (SalesmanResponse?.StatusCode === 6000) {
    salesmanList = SalesmanResponse?.data;
  }

  // const routeResponse = await CallRoutes ({
  //   CompanyID:CompanyID,
  //   BranchID:BranchID,
  //   PriceRounding:Number(PriceDecimalPoint),
  //   CreatedUserID:user_id,
  //   items_per_page: 10,
  //   page_no: 1,
  // })
  // let RouteList = [];
  // if (routeResponse?.StatusCode === 6000) {
  //   RouteList = routeResponse?.data;
  // }
  setFilterOptions({
    ...filterOptions,
    userList,
    WarehouseList,
    salesmanList,
    // RouteList,
  });
  setIsLoadingUser(false);
  setIsLoadingSalesman(false);
}

const fetchRoutes = async function() {
  setIsLoadingRoutes(true)
  const routeResponse = await CallRoutes({
    CompanyID:CompanyID,
    BranchID:BranchID,
    PriceRounding:Number(PriceDecimalPoint),
    CreatedUserID:user_id,
    items_per_page: 10,
    page_no: 1,
    search: routeName,
  })
  let RouteList = [];
  if (routeResponse?.StatusCode === 6000){
    RouteList = routeResponse?.data
    setIsLoadingRoutes(false);
  }
  setFilterOptions({
    ...filterOptions,
    RouteList,
  })
}

// handle RouteList
const handleRouteList = (exp) => {
  let list = [0];
  let routevalue = filterOptions.routeListValue ;
  const routeids = routevalue?.map(i => i.RouteID)
  list.push(...routeids);
  setRoutes(list);
  return list
}
  // API Fetch data
const fetchReport = async function () {
  setLoading(true)
  let RouteID = await handleRouteList()
  const data = await CallBillwiseProfitReport({
    CompanyID:CompanyID,
    BranchID:BranchID,
    PriceRounding:Number(PriceDecimalPoint),
    FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
    ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
    page_no : page_no,
    items_per_page : appSetting.itemPerPage,
    RouteID,
    is_cost_with_tax: filterOptions.purchasecost.is_cost_with_tax.isActive,
    CustomerID : filterOptions?.partyObj?.PartyID ? filterOptions.partyObj.PartyID : 0,
    EmployeeID: filterOptions.salesmanObj?.EmployeeID? filterOptions.salesmanObj.EmployeeID: 0,
    PER_PAGE : appSetting.itemPerPage,
    WareHouseID:filterOptions.WarehouseObj?.WarehouseID?filterOptions.WarehouseObj.WarehouseID : 0 ,
    UserID : filterOptions.UserObj?.UserID?filterOptions.UserObj.UserID : 0,
  })
  console.log(data,'daadtatadataadadaadatad');
  if (data?.StatusCode === 6000) {
    setReportData(data)
  } else if (data.StatusCode === 6001) {
    setReportData()
    dispatch(
      openSnackbar({
        open : true,
        message : data.message,
        severity : 'warning',
      })
    )
  } else if (data?.StatusCode === 6002) {
    setReportData()
    dispatch(
      openSnackbar({
        open : true,
        message : data.message,
        severity : 'error',
      })
    )
  }
  setLoading(false)
}
  const getDataForPage = () => {
    const startIndex = (page_no -1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.data?.slice(0,reportData?.data?.length -1)
    return alldata?.slice(startIndex, endIndex) || [];
  }

  useEffect(() => {
    filterApis()
  }, [])
  const handleTableRowClick = (data) => {
    const destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/sale-invoice-single?id=${data.id}`;
    window.open(destinationURL, '_blank');
  };
  // useEffect(() => {
  //   fetchReport()
  // },[page_no])
  
  useEffect(() => {
    fetchRoutes()
  }, [routeName])

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className='left'>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t('Billwise Profit Report')}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className='right'>
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              invoice_type={"billwise_profit_report"}
              FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
              UserID={filterOptions.UserObj?.UserID?filterOptions.UserObj.UserID : 0}
              WarehouseID={filterOptions.WarehouseObj?.WarehouseID?filterOptions.WarehouseObj.WarehouseID : 0}
              CustomerID={filterOptions?.partyObj?.PartyID ? filterOptions.partyObj.PartyID : 0}
              RouteID={Routes}
              EmployeeID={filterOptions.salesmanObj?.EmployeeID? filterOptions.salesmanObj.EmployeeID: 0}
              filterValue={filterOptions.purchasecost.is_cost_with_tax.isActive}
              handleRouteList={handleRouteList}
            />
            : null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData?.data.length-1}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
        <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
        {handleFilterList(filterOptions).map((val, i) => (
          <FilterLable
            name={val.name}
            value={val.value}
            handleDelete={val.handleDelete?val.handleDelete:null}
          />
        ))}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('Serial No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Date')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Invoice No')}	</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Gross Amount')}	</VBTableCellHeader>
            {VAT && <VBTableCellHeader sx={{textAlign:"right"}}>{t('VAT Amount')}</VBTableCellHeader>}
            {GST && <VBTableCellHeader sx={{textAlign:"right"}}>{t('TAX Amount')}</VBTableCellHeader>}
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Net Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Net Cost')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Profit')}</VBTableCellHeader>
          </VBTableHeader>

          {Loading ? 
          <VBSkeletonLoader /> :
          <TableBody>
            {getDataForPage().map((data, i) => (
            <TableRow
            onClick={() =>
              handleTableRowClick(data, data.VoucherType, data.VoucherNo)
            }>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.index}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.Date}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.InvoiceNo}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.GrossAmount)}</VBTableCellBody>
              {(VAT || GST) && <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.TotalTax)}</VBTableCellBody>}
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.NetAmount)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.NetCost)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.Profit)}</VBTableCellBody>
            </TableRow>
            ))}
            <TableRow></TableRow>
          {/* <VBTableRowNull length = {reportData?.data?.length}/> */}
          </TableBody>
          }
          <VBTableFooter >
            <TableRow>
              <VBTableCellFooter >{t('Total')}</VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data.length -1]?.GrossAmount ?? 0)}</VBTableCellFooter>
              {(VAT || GST) && <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data.length -1]?.TotalTax ?? 0)}</VBTableCellFooter>}
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data.length -1]?.NetAmount ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data.length -1]?.NetCost ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data.length -1]?.Profit ?? 0)}</VBTableCellFooter>
            </TableRow></VBTableFooter>
        </VBTableContainer>
      </Paper>


      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                  handleAutoComplete={handleAutoComplete}
                  dateValue={filterOptions.date.value}
                  isDateRange={true}
                  fromDate={filterOptions.date.FromDate}
                  toDate={filterOptions.date.ToDate}
                  fromOnChange={(value) =>
                    setFilterOptions({
                      ...filterOptions,
                      date: {
                        ...filterOptions.date,
                        FromDate: value,
                        value:{ name: "Custom" },
                      },
                    })
                  }
                  toOnChange={(value) =>
                    setFilterOptions({
                      ...filterOptions,
                      date: {
                        ...filterOptions.date,
                        ToDate: value,
                        value:{ name: "Custom" },
                      },
                    })
                  }
                />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("User")}
                filterName = {t("User")}
                loading = {isLoadingUser}
                options = {filterOptions.userList ? filterOptions.userList : []}
                getOptionLabel={(option) => option.customer_name || "" }
                name = "UserObj"
                onInputChange={(event, value, reason) => { 
                  // if (reason === "input") {
                  //   handleSearchUser(event,value);
                  // }
                  if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, UserObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "UserObj")}
                value={filterOptions.UserObj}
                
              />
            </ListItem>
            <Divider />
            {Is_SalesMan ? 
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                placeholder={t("Salesman")}
                filterName={t("Salesman")}
                loading={isLoadingSalesman}
                options={
                  filterOptions.salesmanList ? filterOptions.salesmanList : []
                }
                getOptionLabel={(option) => option.LedgerName || ""}
                name="salesmanObj"
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    handleSearchEmployee(event, value);
                  }
                  if (reason === "clear") {
                    handleSearchEmployee();
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "salesmanObj", -1)}
                value={filterOptions.salesmanObj}
              />
            </ListItem>
            :null}
            <Divider />
            {Is_Warehouse ? 
            <ListItem>
              <ViknAutoComplete 
                placeholder = {t('Warehouse')}
                label = {t("Warehouse")}
                options = {filterOptions.WarehouseList ? filterOptions.WarehouseList : []}
                getOptionLabel={(option) => option.WarehouseName || "" } 
                value = {filterOptions.WarehouseObj}  
                onChange={(e, v) => handleAutoComplete(e, v, "WarehouseObj")}
              />
            </ListItem>
            :null}
            <Divider />
            <ListItem>
              <ViknRadioGroup 
                radios={filterOptions.filtertypes
                  .filter(item => item !== null) 
                  .map(item => ({
                    label: item?.name,
                    value: item?.value,
                  }))
                }
                handleChange={(e) => handlechange(e, 'filtertype')}
                value = {filterOptions.filtertype}
                valueName = {'filtertype'}
              />
            </ListItem>
            {filterOptions.filtertype === 'customer' &&
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("Customer")}
                filterName={t("Customer")}
                loading={isLoadingParty}
                options={filterOptions.partyList ? filterOptions.partyList : []}
                getOptionLabel={(option) => option.PartyName || ""}
                name="partyObj"
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    (option.PartyName && option.PartyName.toLowerCase().includes(inputValue.toLowerCase())) ||
                    (option.PartyCode && option.PartyCode.toLowerCase().includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.PartyCode}</Box>
                    <Box sx={{fontSize: "14px",}}>{option.PartyName}</Box>
                  </Box>
                )}
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    handleSearchParty(event, value);
                  }
                  if (reason === "clear") {
                    handleSearchParty();
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "partyObj")}
                value={filterOptions.partyObj}
              />
            </ListItem>
            }
            {filterOptions.filtertype === 'route' && Is_Route &&
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                multiple
                loading={isLoadingRoutes}
                placeholder={t("Route")}
                filterName={t("Route Name")}
                options={filterOptions.RouteList ? filterOptions.RouteList : []}
                getOptionLabel={(option) => option.RouteName || ""}
                name="routeListValue"
                onInputChange={(event, value, reason) => {
                  console.log(value, reason);
                  if (reason === "input") {
                    setRouteName(value);
                  }
                  if (reason === "clear") {
                    setFilterOptions({ ...filterOptions, ledgerName: null });
                    setRouteName("");
                  }
                }}
                onChange={(e, v) =>
                  handleAutoComplete(e, v, "routeListValue")
                }
                value={filterOptions.routeListValue}
              />
            </ListItem>
            }
            <Divider />
              <ListItem>
                <ViknCheckboxGroup 
                  radios = {
                    [{
                      label : t('Purchase Cost with Tax'),
                      checked : filterOptions.purchasecost.is_cost_with_tax.isActive,
                      onChange : () => handleChangeCheckBox()
                    }]
                  }
                />
              </ListItem>
            <Divider />
          </List>
        }
      />
    </>
  )
}

export default BillwiseProfitReport

const FlexBox = styled(Box)(() => ({
  display: "flex",
  // justifyContent: "space-between",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));