import {
  Box,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
  styled,
  createTheme,
  ThemeProvider
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterLable from "../../../../Components/Utils/FilterLable";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import { useSelector } from "react-redux";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import moment from "moment";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import VBInputField from "../../../../Components/Utils/VBInputField";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import { CallLedgerReport } from "../../../../Api/Reports/AccountApi";
import { callAccountGroup } from "../../../../Api/Reports/CommonReportApi";
import { useTranslation } from "react-i18next";
import { callSearchAccountLedgersReport } from "../../../../Api/Reports/PurchaseApi";
import { formatNumber } from "../../../../generalFunction";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import LoaderLite from "../../../../Components/Utils/LoaderLite";
import { goToInvoice } from "../../../../Function/comonFunction";
import { useSearchParams } from "react-router-dom";
import { UserRolePermission } from "../../../../Function/Editions";
const theme = createTheme({
  overrides: {
    MuiAutocomplete: {
      option: {
        '&.MuiAutocomplete-option': {
          padding: '0 3px', // Adjust padding as needed
        },
      },
    },
  },
});
const LedgerReport = () => {
  const [t] = useTranslation("common");
  let Export_view = UserRolePermission("Ledger Report", "print_permission");
  // Declear state
  const [searchParams] = useSearchParams();
  const id = searchParams.get('LedgerID');
  const fromdate = searchParams.get('FromDate');
  const date = searchParams.get('ToDate');
  const name = searchParams.get('LedgerName');

  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);

  const appSetting = useSelector((state) => state.appSettingsSclice);
  const {generalSettings} = useSelector((state) => state.generalSettingsSclice);
  const [page_no, setPage_no] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);
  const [progress, setProgress] = useState(0)
  const [isAllBranch, setIsAllBranch] = useState(false);
  const [ledgerName, setLedgerName] = useState("");
  const [filterOptions, setFilterOptions] = useState({
    FromDate: moment(),
    ToDate: moment(),
    isAll: true,
    isFromdate: false,
    isLedgerWise: false,
    isGroupWise: false,
    manuelBalance: "",
    groupName: null,
    ledgerName: null,
  });
  const [filterData, setFilterData] = useState({
    reportList: [],
    reportTotal: {
      debit: "",
      credit: "",
      balance: "",
    },
    count: 0,
    ledgerList: [],
    groupList: [],
  });
  const [isLoading, setIsLoading] = useState({
    ledger: false,
    report: false
  })

  // console.log(searchParams, id, name, date, "Some how it is happend");

  const checkBoxList = [
    {
      checked: filterOptions.isAll,
      onChange: () => {
        setFilterOptions({
          ...filterOptions,
          isAll: !filterOptions.isAll,
          isGroupWise: false,
          isLedgerWise: false,
          isFromdate: false,
          groupName: null,
          ledgerName: null,
        })
        setFilterData({ ...filterData, reportList: [],
          reportTotal: {
            debit: "",
            credit: "",
            balance: "",
          }, })
      },
      label: t("All"),
    },
    {
      checked: filterOptions.isFromdate,
      disabled: filterOptions.isLedgerWise || filterOptions.isGroupWise,
      onChange: () =>{
        setFilterOptions({
          ...filterOptions,
          isFromdate: !filterOptions.isFromdate,
          groupName: null,
          ledgerName: null,
        })
      },
      label: t("From Date"),
    },
    {
      checked: filterOptions.isLedgerWise,
      onChange: () =>{
        setFilterOptions({
          ...filterOptions,
          isLedgerWise: !filterOptions.isLedgerWise,
          isAll: false,
          isFromdate: true,
          isGroupWise: false,
          groupName: null,
          ledgerName: null,
        })
        setFilterData({ ...filterData, reportList: [] })
      },
      label: t("Ledger Wise"),
    },
    {
      checked: filterOptions.isGroupWise,
      onChange: () => {
        setFilterOptions({
          ...filterOptions,
          isGroupWise: !filterOptions.isGroupWise,
          isLedgerWise: false,
          isFromdate: true,
          isAll: false,
          groupName: null,
          ledgerName: null,
        })
        setFilterData({ ...filterData, reportList: [] })
      },
      label: t("Group Wise"),
    },
  ];

  // Handle fundtions
  const closeFilter = () => setOpenFilter(false);
  const filterReport = function () {
    fetchReport();
  };

  const handleDefaultFilter = function() {
    console.log(" function not working **************8(((((((999)))))))))))");
    setFilterOptions({
      FromDate: moment(),
      ToDate: moment(),
      isAll: true,
      isFromdate: false,
      isLedgerWise: false,
      isGroupWise: false,
      manuelBalance: "",
      groupName: null,
      ledgerName: null,
    })
  }

  // const getDataForPage = () => {
  //   const startIndex = (page_no - 1) * appSetting.itemPerPage;
  //   const endIndex = startIndex + appSetting.itemPerPage;
  //   const alldata = filterData.reportList
  //   return alldata.slice(startIndex, endIndex) || [];
  // };

  // Fetch Data
  const fetchReport = async function () {
    setIsLoading({
      ...isLoading,
      report: true,
    });
    let payload = {
      FromDate: filterOptions.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.ToDate.format("YYYY-MM-DD"),
      ID: id ? 1 : 0,
      value: id ? id : 0,
      ManualOpeningBalance: filterOptions.manuelBalance,
      is_fromDate: date ? true : filterOptions.isFromdate,
      page_no: page_no,
      page_size: appSetting.itemPerPage,
      is_all_branch: id ? true : isAllBranch,
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      CreatedUserID: user_id,
    };

    if (filterOptions.isLedgerWise) payload.ID = 1;
    if (filterOptions.isLedgerWise && filterOptions.ledgerName)
      payload.value = filterOptions.ledgerName.LedgerID;
    if (filterOptions.isGroupWise) payload.ID = 2;
    if (filterOptions.isGroupWise && filterOptions.groupName)
      payload.value = filterOptions.groupName.AccountGroupID;

    const res = await CallLedgerReport(payload);
    if (res.StatusCode === 6000) {
      setFilterData( prev =>({
        ...prev,
        reportList: res.new_data,
        reportTotal: res.total,
        count: res.count,
      }));
    }
    setIsLoading({
      ...isLoading,
      report: false,
    });
  };

  const searchLedger = async function () {
    setIsLoading({
      ...isLoading,
      ledger: true,
    });
    const res = await callSearchAccountLedgersReport({
      product_name: ledgerName,
      length: 2,
      param: "name",
      CompanyID:CompanyID,
      BranchID:BranchID,
      CreatedUserID:user_id
    });

    if (res.StatusCode === 6000) {
      setFilterData(prev => ({
        ...prev,
        ledgerList: res.data,
      }));
    }
    setIsLoading({
      ...isLoading,
      ledger: false,
    });
  };

  const filteApi = async function () {
    const groupRes = await callAccountGroup({CompanyID:CompanyID,BranchID:BranchID,CreatedUserID:user_id});
    if (groupRes.StatusCode === 6000) {
      setFilterData(prev => ({ ...prev, groupList: groupRes.data }));
    }
  };

  useEffect(() => {
    fetchReport()
  }, [page_no])

  useEffect(() => {
    filteApi();
  }, []);

  useEffect(() => {
    searchLedger();
  }, [ledgerName]);
  
  useEffect(() => {
    id && setFilterOptions({
      ...filterOptions,
      ledgerName : { LedgerName: name, LedgerID: id, LedgerCode: " " },
      isAll: false,
      isLedgerWise: true,
      isFromdate: true,
      ToDate: moment(date),
      FromDate:moment(fromdate)
    })
    // let item =filterData.ledgerList.filter(e => e.LedgerID === id)
    // console.log(item);
    // id && filterReport()
  }, [])

  return (
    <>
      {progress > 0 ?
      // <LoaderLite message={"Generating Report... " + ((progress<100)?(progress + "%"):'')} progress={progress} fullscreen={true} />
      <LoaderLite message={((progress<=100)?("Generating Report... " + progress + "%"):"Creating File... ")} progress={progress} fullscreen={true} />
      :null}
      <Paper sx={{ height: "100%", width: "100%" }}>
        {/* Top header */}
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center"}}>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
              {t("Ledger Report")}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </Box>

          <FlexBox>
          {Export_view ? 
          <ReportDownload
            download_type={"PDF"}
            VoucherType={
              (() => { 
                if(filterOptions.isLedgerWise) return "ledger_wise" 
                if(filterOptions.isLedgerWise) return "group_wise"
                return "all_ledger"
              })()
            }
            invoice_type={"ledger_report"}
            FromDate={filterOptions.FromDate.format("YYYY-MM-DD")}
            ToDate={filterOptions.ToDate.format("YYYY-MM-DD")}
            ManualOpeningBalance={filterOptions.manuelBalance}
            value={filterOptions.isLedgerWise && filterOptions.ledgerName ? filterOptions.ledgerName.LedgerID : 0}
            // PriceRounding={state.PriceRounding}
            is_celery={true}
            setProgress={setProgress}
            is_all_branch={isAllBranch}
            // UserID={0}
          />
          : null}
            {/* <ExportBtn /> */}
            {/* <PrintBtn /> */}
            <Pagenation 
              page_no={page_no}
              setPage_no = {setPage_no}
              totalItem={filterData.count}
            />
          </FlexBox>
        </Box>

        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FilterLable
            name={t("Date")}
            value={(filterOptions.isFromdate ? filterOptions.FromDate.format("DD/MM/YYYY") + " - " : " ") + filterOptions.ToDate.format("DD/MM/YYYY") }
          />
          {filterOptions.isAll && <FilterLable
            name={t("Report Type")}
            value={"All"}
          />}
          {filterOptions.isGroupWise && <FilterLable
            name={t("Report Type")}
            value={"Group Wise"}
          />}
          {filterOptions.isLedgerWise &&<FilterLable
            name={t("Report Type")}
            value={"Ledger Wise"}
          />}
          { isAllBranch && <FilterLable
            name={t("Branches")}
            value={t("Show All Branches")}
          />}
          {(filterOptions.isGroupWise && filterOptions.groupName) && <FilterLable
            name={t("Group Name")}
            value={filterOptions.groupName.AccountGroupName}
            handleDelete={() => setFilterOptions({...filterOptions, groupName: null})}
          />}
          {(filterOptions.isLedgerWise && filterOptions.ledgerName) && <FilterLable
            name={t("Ledger Name")}
            value={filterOptions.ledgerName.LedgerName}
            handleDelete={() => setFilterOptions({...filterOptions, ledgerName: null})}
          />}

          {(filterOptions.isLedgerWise && filterOptions.manuelBalance) && <FilterLable
            name={t("Balance")}
            value={filterOptions.manuelBalance}
            handleDelete={() => setFilterOptions({...filterOptions, manuelBalance: ""})}
          />}
        </Box>

        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("SI NO")}</VBTableCellHeader>
            {/* <VBTableCellHeader> Ledger Name </VBTableCellHeader> */}

            {filterOptions.isLedgerWise && [
              <VBTableCellHeader>{t("Voucher No")} </VBTableCellHeader>,
              <VBTableCellHeader> {t("Date")} </VBTableCellHeader>,
              <VBTableCellHeader>{t("Particulars")} </VBTableCellHeader>,
            ]}
            <VBTableCellHeader>{t("Ledger Name")} </VBTableCellHeader>
            {(filterOptions.isLedgerWise || filterOptions.isGroupWise )&& 
            <VBTableCellHeader>{t("Username")} </VBTableCellHeader>}

            {(filterOptions.isGroupWise || filterOptions.isLedgerWise) && <>
              <VBTableCellHeader sx={{textAlign: "right"}}>{t("Debit")} </VBTableCellHeader>
              {generalSettings.EnableForeignCurrency && <VBTableCellHeader sx={{textAlign: "right"}}>{t("Debit(FC)")} </VBTableCellHeader>}
              <VBTableCellHeader sx={{textAlign: "right"}}>{t("Credit")} </VBTableCellHeader>
              {generalSettings.EnableForeignCurrency && <VBTableCellHeader sx={{textAlign: "right"}}>{t("Credit(FC)")} </VBTableCellHeader>}
            </>}

            <VBTableCellHeader sx={{textAlign: "right"}}>{t("Balance")} </VBTableCellHeader>

            {filterOptions.isLedgerWise && [
              <VBTableCellHeader >{t("Notes")} </VBTableCellHeader>,
              <VBTableCellHeader >{t("Reference No")} </VBTableCellHeader>,
            ]}
          </VBTableHeader>
          {isLoading.report ? (
            <VBSkeletonLoader />
          ) : <TableBody>
            {filterData.reportList.map((e, i) => (
              <TableRow sx={{ cursor: e.id ? "pointer" : 'auto'}} onClick={() =>  e.id ? goToInvoice(e.id, e.VoucherType, e.VoucherNo,) : null } >
                <VBTableCellBody> {(page_no - 1)* appSetting.itemPerPage + i + 1} </VBTableCellBody>
                {filterOptions.isLedgerWise && [
                  <VBTableCellBody> {e.VoucherNo} </VBTableCellBody>,
                  <VBTableCellBody> {e.Date} </VBTableCellBody>,
                  <VBTableCellBody> {e.Particulars} </VBTableCellBody>,
                ]}

                <VBTableCellBody> {e.LedgerName} </VBTableCellBody>
                {(filterOptions.isLedgerWise || filterOptions.isGroupWise )&& 
                <VBTableCellBody> {e.Username} </VBTableCellBody>
                }

                {(filterOptions.isGroupWise || filterOptions.isLedgerWise) && <>
                  <VBTableCellBody sx={{textAlign: "right"}}> {formatNumber(e.Debit)} </VBTableCellBody>
                  {generalSettings.EnableForeignCurrency && <VBTableCellBody sx={{textAlign: "right"}}> {formatNumber(e.DebitFC)} {e.ForiegnCurrencySymbol? `(${e.ForiegnCurrencySymbol})`:null} </VBTableCellBody>}
                  <VBTableCellBody sx={{textAlign: "right"}}> {formatNumber(e.Credit)} </VBTableCellBody>
                  {generalSettings.EnableForeignCurrency && <VBTableCellBody sx={{textAlign: "right"}}> {formatNumber(e.CreditFC)} {e.ForiegnCurrencySymbol? `(${e.ForiegnCurrencySymbol})`:null} </VBTableCellBody>}
                </>}

                <VBTableCellBody sx={{textAlign: "right"}}> {formatNumber(e.Balance)} </VBTableCellBody>

                {filterOptions.isLedgerWise && [
                  <VBTableCellBody > 
                    <Tooltip title={e.Narration}>
                    {
                      e.Narration && e.Narration?.length >= 100
                        ? String(e.Narration).slice(0, 99) + "..."
                        : e.Narration
                    }
                    </Tooltip>
                  </VBTableCellBody>,
                  <VBTableCellBody > 
                    <Tooltip title={e.ref_no}>
                    {
                      e.ref_no && e.ref_no?.length >= 100
                        ? String(e.ref_no).slice(0, 99) + "..."
                        : e.ref_no
                    }
                    {/* {e.ref_no >= 100 ? String(e.ref_no).slice(0, 50) + "..." : e.ref_no} */}
                    </Tooltip>
                  </VBTableCellBody>,
                ]}
              </TableRow>
            ))}
            <TableRow></TableRow>
          </TableBody>}
          {(filterOptions.isLedgerWise && !isLoading.report) && (
            <VBTableFooter>
              <TableRow>
                <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
                <VBTableCellFooter></VBTableCellFooter>
                <VBTableCellFooter></VBTableCellFooter>
                <VBTableCellFooter></VBTableCellFooter>
                <VBTableCellFooter></VBTableCellFooter>
                <VBTableCellFooter sx={{textAlign: "right"}}>
                  {formatNumber(filterData.reportTotal.debit)}{" "}
                </VBTableCellFooter>
               {generalSettings.EnableForeignCurrency && <VBTableCellFooter></VBTableCellFooter>}
                <VBTableCellFooter sx={{textAlign: "right"}}>
                  {formatNumber(filterData.reportTotal.credit)}{" "}
                </VBTableCellFooter>
                {generalSettings.EnableForeignCurrency && <VBTableCellFooter></VBTableCellFooter>}
                <VBTableCellFooter sx={{textAlign: "right"}}>
                  {formatNumber(filterData.reportTotal.balance)}{" "}
                </VBTableCellFooter>
                <VBTableCellFooter></VBTableCellFooter>
                <VBTableCellFooter></VBTableCellFooter>
              </TableRow>
            </VBTableFooter>
          )}
        </VBTableContainer>
      </Paper>

      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={filterReport}
        handleDefaultFilter={() => handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {generalSettings.EnableBranch && <ListItem>
              <ViknCheckboxGroup
                isBorder={true}
                radios={[
                  {
                    checked: isAllBranch,
                    onChange: () => setIsAllBranch(!isAllBranch),
                    label: t("Show All Branches"),
                  },
                ]}
              />
            </ListItem>}
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.isFromdate ? filterOptions.FromDate : null}
                toDate={filterOptions.ToDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    FromDate: value,
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    ToDate: value,
                  })
                }
              />
            </ListItem>
            <ListItem>
              <ViknCheckboxGroup
                isBorder={true}
                labelName={t("Report Type")}
                radios={checkBoxList}
              />
            </ListItem>
            <Zoom in={filterOptions.isGroupWise} unmountOnExit>
              <ListItem>
                <ViknSearchAutoComplete
                  placeholder={t("Group")}
                  options={filterData.groupList}
                  value={filterOptions.groupName}
                  getOptionLabel={(option) => option.AccountGroupName}
                  onChange={(e, v) =>
                    setFilterOptions({ ...filterOptions, groupName: v })
                  }
                />
              </ListItem>
            </Zoom>

            
            <ThemeProvider theme={theme}>
              <Zoom in={filterOptions.isLedgerWise} unmountOnExit>
                  <ListItem>
                    <ViknSearchAutoComplete
                      freeSolo
                      placeholder={t("Ledger Name")}
                      loading={isLoading.isLoadingBatch}
                      options={filterData.ledgerList}
                      PaperComponent={Link}
                      value={filterOptions.ledgerName}
                      getOptionLabel={(option) => option.LedgerName || ""}
                      filterOptions={(options, { inputValue }) =>
                        options.filter((option) =>
                          (option.LedgerName && option.LedgerName.toLowerCase().includes(inputValue.toLowerCase())) ||
                          (option.LedgerCode && option.LedgerCode.toLowerCase().includes(inputValue.toLowerCase()))
                        )
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                          {...props}
                        >
                          <Box
                            sx={{
                              flex: '0 0 auto',
                              fontSize: "14px",
                              color: "#797979",
                              marginRight: 2,
                              textAlign: 'left',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {option.LedgerCode}
                          </Box>
                          <Box
                            sx={{
                              flex: '1 1 auto',
                              fontSize: "14px",
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {option.LedgerName}
                          </Box>
                          <Box
                            sx={{
                              flex: '0 0 auto',
                              fontSize: "14px",
                              color: option.LedgerBalance < 0 ? "red" : "green",
                              marginLeft: 2,
                              textAlign: 'right',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {option.LedgerBalance}
                          </Box>
                        </Box>
                      )}

                      onChange={(e, v) =>
                        setFilterOptions({ ...filterOptions, ledgerName: v })
                      }
                      onInputChange={(event, value, reason) => {
                        if (reason === "input") {
                          setLedgerName(value);
                        }
                        if (reason === "clear") {
                          setFilterOptions({ ...filterOptions, ledgerName: null });
                          setLedgerName("");
                        }
                      }}
                      sx={{ width: '100%' }}
                    />
                  </ListItem>
              </Zoom>
            </ThemeProvider>
            
            <Zoom in={filterOptions.isLedgerWise} unmountOnExit>
              <ListItem>
                <VBInputField placeholder={t("Manual Opening Balance")} value={filterOptions.manuelBalance} onChange={(e) => setFilterOptions({...filterOptions, manuelBalance: e.target.value})}/>
              </ListItem>
            </Zoom>
          </List>
        }
      />
    </>
  );
};

export default LedgerReport;

const Link = ({ children, ...other }) => {
  // const { ShowCustomerCode } = useSelector(
  //   (state) => state.generalSettingsSclice
  // );

  const ShowCustomerCode = true;

  const [t] = useTranslation("common");

  return (
    <Paper {...other}>
      <table style={{width:"100%"}}>
        {children[2] && (
          <thead style={{ display: "block" }}>
            <tr>
              {ShowCustomerCode ? (
                <th
                  style={{
                    fontWeight: "bold",
                    padding: 5,
                    fontSize: "14px",
                    width: "120px",
                    textAlign: "left",
                  }}
                >
                  {/* {t("Customer Code")} */}
                  {t("Code")}
                </th>
              ) : null}
              {ShowCustomerCode ? (
                <th
                  style={{
                    fontWeight: "bold",
                    padding: 5,
                    fontSize: "14px",
                    width: "120px",
                    textAlign: "left",
                  }}
                >
                  {t("Name")}
                  {/* {t("Customer Name")} */}
                </th>
              ) : null}
            </tr>
          </thead>
        )}
        {children}
      </table>
    </Paper>
  );
};

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // width: "27%",
}));
