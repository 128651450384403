import {
  Box,
  Chip,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import SearchReport from "../../../../Components/Utils/SearchReport";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import {
  CallProductSummaryReport,
  CallStockLedger,
} from "../../../../Api/Reports/InventoryApi";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import {
  CallProductSearch,
  CallWarehouses,
} from "../../../../Api/Reports/CommonReportApi";
import { useDispatch, useSelector } from "react-redux";
import { debounce1, goToInvoice } from "../../../../Function/comonFunction";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { formatNumber } from "../../../../generalFunction";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { useTranslation } from "react-i18next";
import { UserRolePermission } from "../../../../Function/Editions";

const StockLedgerReport = () => {
  let Export_view = UserRolePermission(
    "Stock Ledger Report",
    "print_permission"
  );
  const [t] = useTranslation("common");
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { PriceDecimalPoint } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const { user_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [page_no, setPage_no] = useState(1);
  const [clearFilter, setClearFilter] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    product: null,
    wareHouse: null,
    isShowPurchase: false,
    fromDate: moment(),
    toDate: moment(),
    showActiveProductsOnly: true,
  });

  const [filterData, setFilterData] = useState({
    productList: [],
    wareHouseList: [],
  });

  const [isLoading, setIsLoading] = useState({
    isLoadingProduct: false,
    report: false,
  });

  const [search, setSearch] = useState({
    product: "",
  });

  //Handle functions
  const closeFilter = () => setOpenFilter(false);

  const handleDefaultFilter = function () {
    setFilterOptions({
      product: null,
      wareHouse: null,
      isShowPurchase: false,
      fromDate: moment(),
      toDate: moment(),
    });
    setClearFilter(!clearFilter);
    page_no === 1 && fetchReport(false);
    setPage_no(1);
  };

  const searchReport = function () {
    fetchReport();
    setPage_no(1);
  };

  // API Fetch data
  const fetchReport = async function () {
    setIsLoading({ ...isLoading, report: true });
    const payload = {
      FromDate: filterOptions.fromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.toDate.format("YYYY-MM-DD"),
      ProductID: 0,
      WareHouseID: 0,
      page_no: 1,
      items_per_page: 15,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
    };
    if (filterOptions.wareHouse)
      payload.WareHouseID = filterOptions.wareHouse.WarehouseID;
    if (filterOptions.product)
      payload.ProductID = filterOptions.product.ProductID;
    const data = await CallStockLedger(payload);
    // console.log(data);
    if (data?.StatusCode === 6000) {
      setReportData(data.data);
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: data?.message,
          severity: "warning",
        })
      );
      setReportData([]);
    }
    setIsLoading({ ...isLoading, report: false });
  };

  const filterApis = async function () {
    const warehouse = await CallWarehouses({
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      show_all: true,
    });
    console.log(warehouse);
    if (warehouse.StatusCode === 6000) {
      setFilterData((prev) => ({ ...prev, wareHouseList: warehouse.data }));
    }
  };

  const searchProduct = async function () {
    setIsLoading({ ...isLoading, isLoadingProduct: true });
    const callProduct = await CallProductSearch({
      product_name: search.product,
      length: 1,
      type: "report",
      is_product_image: false,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      showActiveProductsOnly:filterOptions.showActiveProductsOnly
    });
    if (callProduct.StatusCode === 6000) {
      setFilterData((prev) => ({ ...prev, productList: callProduct.data }));
      setIsLoading({ ...isLoading, isLoadingProduct: false });
    }
  };

  const getDataForPage = () => {
    const startIndex = (page_no - 1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData.slice(0, reportData.length - 1);
    return alldata.slice(startIndex, endIndex) || [];
  };

  const debouncedSetFilterOptions = useMemo(
    () =>
      debounce1((newValue) => {
        setFilterOptions({
          ...filterOptions,
          product: newValue,
        });
      }, 300),
    []
  );

  // UseEffect Hell 🔥🔥🔥
  useEffect(() => {
    searchProduct();
  }, [search.product]);

  useEffect(() => {
    filterApis();
  }, []);

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#001746",
                fontSize: "23px",
                fontWeight: "500",
                mr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {t("Stock Ledger Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </Box>

          <FlexBox>
            {Export_view ? (
              <ReportDownload
                download_type={"PDF"}
                invoice_type={"stock_ledger_report"}
                FromDate={filterOptions.fromDate.format("YYYY-MM-DD")}
                ToDate={filterOptions.toDate.format("YYYY-MM-DD")}
                WarehouseID={
                  filterOptions.wareHouse
                    ? filterOptions.wareHouse.WarehouseID
                    : 0
                }
                ProductID={
                  filterOptions.product ? filterOptions.product.ProductID : 0
                }
              />
            ) : null}
            {/* <ExportBtn /> */}
            {/* <PrintBtn               /> */}
            <Pagenation
              totalItem={reportData.length}
              page_no={page_no}
              setPage_no={setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px",
            overflowX: "scroll",
            // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div
            style={{
              color: "#001746",
              fontSize: "15px",
              fontWeight: "400",
              marginRight: "5px",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
              color: "#001746",
            }}
          >
            {t("Applied Filters: ")}
          </div>
          <FilterLable
            name={"Date"}
            value={
              filterOptions.fromDate.format("DD/MM/YYYY") +
              " - " +
              filterOptions.toDate.format("DD/MM/YYYY")
            }
          />

          {filterOptions.product && (
            <FilterLable
              name={"Product"}
              value={filterOptions.product.ProductName}
              handleDelete={() =>
                setFilterOptions({ ...filterOptions, product: null })
              }
            />
          )}

          {filterOptions.wareHouse && (
            <FilterLable
              name={"Warehouse Name"}
              value={filterOptions.wareHouse.name}
              handleDelete={() =>
                setFilterOptions({ ...filterOptions, wareHouse: null })
              }
            />
          )}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher Date")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher Type")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Ledger Name")} </VBTableCellHeader>
            <VBTableCellHeader>{t("User")} </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Unit")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("UnitPrice")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Qty")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Free Qty")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Qty In")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Qty Out")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Balance")}{" "}
            </VBTableCellHeader>
            {filterOptions.isShowPurchase && (
              <VBTableCellHeader sx={{ textAlign: "right" }}>
                {t("PurchaseNo")}{" "}
              </VBTableCellHeader>
            )}
          </VBTableHeader>
          {isLoading.report ? (
            <VBSkeletonLoader />
          ) : (
            [
              <TableBody>
                {getDataForPage().map((data, i) => (
                  <TableRow
                    sx={{
                      cursor:
                        data.id === "nan" || data.id === ""
                          ? "auto"
                          : "pointer",
                    }}
                    onClick={() =>
                      data.id === "nan" || data.id === ""
                        ? null
                        : goToInvoice(data.id, data.VoucherType, data.VoucherNo)
                    }
                  >
                    <VBTableCellBody>
                      {(page_no - 1) * appSetting.itemPerPage + i + 1}
                    </VBTableCellBody>
                    <VBTableCellBody>{data.VoucherNo}</VBTableCellBody>
                    <VBTableCellBody>{data.Date}</VBTableCellBody>
                    <VBTableCellBody>{data.VoucherType}</VBTableCellBody>
                    <VBTableCellBody>{data.LedgerName}</VBTableCellBody>
                    <VBTableCellBody>{data.UserName}</VBTableCellBody>
                    <VBTableCellBody>{data.UnitName}</VBTableCellBody>
                    <VBTableCellBody sx={{ textAlign: "right" }}>
                      {formatNumber(data.UnitPrice)}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ textAlign: "right" }}>
                      {data.Qty}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ textAlign: "right" }}>
                      {data.FreeQty}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ textAlign: "right" }}>
                      {data.QtyIn} {data.QtyIn !== 0 && data.BaseUnitName}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ textAlign: "right" }}>
                      {data.QtyOut} {data.QtyOut !== 0 && data.BaseUnitName}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ textAlign: "right" }}>
                      {data.Stock}
                    </VBTableCellBody>
                    {filterOptions.isShowPurchase && (
                      <VBTableCellBody sx={{ textAlign: "right" }}>
                        {data.PurchaseNo}
                      </VBTableCellBody>
                    )}
                  </TableRow>
                ))}
                <TableRow></TableRow>
              </TableBody>,
              <VBTableFooter>
                <TableRow>
                  <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
                  <VBTableCellFooter></VBTableCellFooter>
                  <VBTableCellFooter></VBTableCellFooter>
                  <VBTableCellFooter></VBTableCellFooter>
                  <VBTableCellFooter></VBTableCellFooter>
                  <VBTableCellFooter></VBTableCellFooter>
                  <VBTableCellFooter></VBTableCellFooter>
                  <VBTableCellFooter sx={{ textAlign: "right" }}>
                    {reportData[reportData.length - 1]?.UnitPrice}
                  </VBTableCellFooter>
                  <VBTableCellFooter sx={{ textAlign: "right" }}>
                    {reportData[reportData.length - 1]?.Qty}
                  </VBTableCellFooter>
                  <VBTableCellFooter sx={{ textAlign: "right" }}>
                    {reportData[reportData.length - 1]?.FreeQty}
                  </VBTableCellFooter>
                  <VBTableCellFooter sx={{ textAlign: "right" }}>
                    {reportData[reportData.length - 1]?.QtyIn}
                  </VBTableCellFooter>
                  <VBTableCellFooter sx={{ textAlign: "right" }}>
                    {reportData[reportData.length - 1]?.QtyOut}
                  </VBTableCellFooter>
                  <VBTableCellFooter sx={{ textAlign: "right" }}>
                    {reportData[reportData.length - 1]?.Stock}
                  </VBTableCellFooter>
                  {filterOptions.isShowPurchase && (
                    <VBTableCellFooter sx={{ textAlign: "right" }}>
                      {reportData[reportData.length - 1]?.PurchaseNo}
                    </VBTableCellFooter>
                  )}
                </TableRow>
              </VBTableFooter>,
            ]
          )}
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={() => searchReport()}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.fromDate}
                toDate={filterOptions.toDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    fromDate: value,
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    toDate: value,
                  })
                }
              />
            </ListItem>
            <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    label: t("Show Inactive Products"),
                    checked: filterOptions.showActiveProductsOnly
                      ? false
                      : true,
                    onChange: () => {
                      setFilterOptions((prev) => ({
                        ...prev,
                        showActiveProductsOnly: !prev.showActiveProductsOnly,
                      }));
                      searchProduct();
                    },
                  },
                ]}
              />
            </ListItem>
            {/* <Divider /> */}
            <ListItem>
              {/* <ViknAutoComplete
                label={"Product"}
                placeholder={"Search for Product"}
                value={filterOptions.product}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({ ...prev, product: newValue }))
                }
                options={filterData.brandList}
                getOptionLabel={(option) => option?.name}
                // isOptionEqualToValue={(option, value) => option?.name === value?.name}
              /> */}
              <ViknSearchAutoComplete
                freeSolo
                // disabled={!filterOptions.productname}
                filterName={t("Product")}
                placeholder={"Search for Product"}
                loading={isLoading.isLoadingProduct}
                options={filterData.productList}
                getOptionLabel={(option) => option.ProductName || ""}
                filterOptions={(options, { inputValue }) =>
                  options.filter(
                    (option) =>
                      (option.ProductName &&
                        option.ProductName.toLowerCase().includes(
                          inputValue.toLowerCase()
                        )) ||
                      (option.ProductCode &&
                        option.ProductCode.toLowerCase().includes(
                          inputValue.toLowerCase()
                        ))
                  )
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Box sx={{ fontSize: "14px", color: "#797979", mr: 2 }}>
                      {option.ProductCode}
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>{option.ProductName}</Box>
                  </Box>
                )}
                // name="partyObj"
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    setSearch({ ...search, product: value });
                  }
                }}
                onChange={(e, v) => {
                  // setFilterOptions({ // make other state null
                  //   ...filterOptions,
                  //   product: v,
                  // })
                  debouncedSetFilterOptions(v);
                }}
                value={filterOptions.product}
              />
            </ListItem>
            <ListItem>
              <ViknAutoComplete
                label={t("Warehouse")}
                placeholder={"Search for Warehouse"}
                value={filterOptions.wareHouse}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({ ...prev, wareHouse: newValue }))
                }
                options={filterData.wareHouseList}
                getOptionLabel={(option) => option?.name}
                // isOptionEqualToValue={(option, value) => option?.name === value?.name}
              />
            </ListItem>
            <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    checked: filterOptions.isShowPurchase,
                    onChange: () =>
                      setFilterOptions((prev) => ({
                        ...prev,
                        isShowPurchase: !prev.isShowPurchase,
                      })),
                    label: t("Show Purchase No (GRN)"),
                  },
                ]}
              />
            </ListItem>
          </List>
        }
      />
    </>
  );
};

export default StockLedgerReport;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  // width: "25%",
}));
