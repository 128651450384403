import moment from "moment";
import {
  ACCOUNTS_API_URL,
  ACCOUNTS_FRONT_URL,
  ADMIN_FRONT_URL,
  MyUrl,
  PAYROLL_FRONT_URL,
  TASK_FRONT_URL,
  VIKNBOOKS_FRONT_URL,
  domain,
} from "../generalVeriable";
import { Decrypt, Encrypt, formatNumber, getCookie } from "../generalFunction";
import axios from "axios";
import { store } from "../app/store";
import { openAlertPopUp } from "../features/SnackBarslice";
const _ = require("lodash");

export const date_range = function (range) {
  let date = {
    fromdate: "",
    todate: "",
  };
  switch (range) {
    case "Last 90 Days":
      date.fromdate = moment().subtract(90, "days");
      date.todate = moment();
      break;
    case "Last Year":
      date.fromdate = moment().subtract(1, "year").startOf("year");
      date.todate = moment().subtract(1, "year").endOf("year");
      break;
    case "Last Month":
      date.fromdate = moment().subtract(1, "month").startOf("month");
      date.todate = moment().subtract(1, "month").endOf("month");
      break;
    case "This Week":
      date.fromdate = moment().startOf("week");
      date.todate = moment().endOf("week");
      break;
    case "This Month":
    case "Monthly":
      date.fromdate = moment().startOf("month");
      date.todate = moment().endOf("month");
      break;
    case "This Year":
      date.fromdate = moment().startOf("year");
      date.todate = moment().endOf("year");
      break;
    case "This Quarter":
      date.fromdate = moment().subtract(3, "month").startOf("month").date(1); // First day of the month 3 months ago
      date.todate = moment(date.fromdate)
        .add(2, "months")
        .date(31)
        .endOf("day"); // Last day of the current month
      break;
    default:
      date.fromdate = moment();
      date.todate = moment();
      break;
  }
  return date;
};

// Go to the invoice by passing the voucher type
export const goToInvoice = function (id, VoucherType, VoucherNo) {
  const voucherTypeUrls = {
    "Sales Invoice": `${MyUrl}/dashboard/sale-invoice-single?id=${id}`,
    SI: `${MyUrl}/dashboard/sale-invoice-single?id=${id}`,
    "Sales Return": `${MyUrl}/dashboard/sales-return-preview?id=${id}`,
    SR: `${MyUrl}/dashboard/sales-return-preview?id=${id}`,
    "Purchase Invoice": `${MyUrl}/dashboard/purchase-invoice-single?id=${id}`,
    PI: `${MyUrl}/dashboard/purchase-invoice-single?id=${id}`,
    "Purchase Return": `${MyUrl}/dashboard/purchase-return-single?id=${id}`,
    PR: `${MyUrl}/dashboard/purchase-return-single?id=${id}`,
    "Opening Stock": `${MyUrl}/dashboard/create-opening-stock?id=${id}`,
    OS: `${MyUrl}/dashboard/create-opening-stock?id=${id}`,
    "Work Order": `${MyUrl}/dashboard/create-work-order?id=${id}`,
    WO: `${MyUrl}/dashboard/create-work-order?id=${id}`,
    "Stock Transfer": `${MyUrl}/dashboard/stock-transfer-single?id=${id}&invoice_type=stock_transfer`,
    ST: `${MyUrl}/dashboard/stock-transfer-single?id=${id}`,
    Expense: `${MyUrl}/dashboard/expense-single?id=${id}`,
    EX: `${MyUrl}/dashboard/expense-single?id=${id}`,
    Payment: `${MyUrl}/dashboard/payment-single?boo=true&is_print=true&id=${id}`,
    CP: `${MyUrl}/dashboard/payment-single?boo=true&is_print=true&id=${id}`,
    BP: `${MyUrl}/dashboard/payment-single?boo=true&is_print=true&id=${id}`,
    Receipt: `${MyUrl}/dashboard/receipt-single?boo=true&is_print=true&id=${id}`,
    BR: `${MyUrl}/dashboard/receipt-single?boo=true&is_print=true&id=${id}`,
    CR: `${MyUrl}/dashboard/receipt-single?boo=true&is_print=true&id=${id}`,
    Journal: `${MyUrl}/dashboard/journal-single?id=${id}`,
    JL: `${MyUrl}/dashboard/journal-single?id=${id}`,
    "Excess Stock": `${MyUrl}/create-stock-management?id=${id}`,
    ES: `${MyUrl}/create-stock-management?id=${id}`,
    "Shortage Stock": `${MyUrl}/create-stock-management?id=${id}`,
    SS: `${MyUrl}/create-stock-management?id=${id}`,
    "Damage Stock": `${MyUrl}/create-stock-management?id=${id}`,
    DS: `${MyUrl}/create-stock-management?id=${id}`,
    "Used Stock": `${MyUrl}/create-stock-management?id=${id}`,
    US: `${MyUrl}/create-stock-management?id=${id}`,
    "Create Product": `${MyUrl}/dashboard/create-products?id=${id}`,
    SBT: `${MyUrl}/dashboard/sale-invoice-single-bt?id=${id}&invoice_type=purchase_invoice_bt&VoucherNo=${VoucherNo}`,
    PBT: `${MyUrl}/dashboard/purchase-invoice-single-bt?id=${id}&invoice_type=purchase_invoice_bt&VoucherNo=${VoucherNo}`,
    "Goods Received Note": `${MyUrl}/dashboard/preview-goods-received-note?id=${id}&invoice_type=goods_received_note&VoucherNo=${VoucherNo}`,
  };

  // Check if VoucherType exists in voucherTypeUrls, and open the corresponding URL
  if (voucherTypeUrls.hasOwnProperty(VoucherType)) {
    window.open(voucherTypeUrls[VoucherType], "_blank");
  } else {
    console.log("Voucher type not found in voucherTypeUrls");
  }
};

export const handleCashLedgers = async (filterOptions, notStartZero) => {
  let list = [0];
  if (notStartZero) {
    list = [];
  }
  let ledgerValue = filterOptions.ledgerValue;
  const ledgerIDs = ledgerValue.map((item) => item.LedgerID);
  list.push(...ledgerIDs);
  return list;
};
// to get avatar images just pass a value you will get a random image
export const getAvatar = (value = "1", imageType = "identicon", res = 46) => {
  const hashCode = (s) =>
    s.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return Math.abs(a & a);
    }, 0);

  // Create an hash of the string
  const hash = hashCode(value);
  // Grab the actual image URL

  // return `https://avatars.dicebear.com/api/${imageType}/${hash}.svg`;
  return `https://www.gravatar.com/avatar/${hash}?s=${res}&d=${imageType}&r=PG&f=1`;
};
export function checkWordLength(word) {
  let str = new String(word);
  let length = str.length;
  if (length > 13) {
    return true;
  }
  return false;
}
export const get_unq_browserID = async (data) => {
  // const biri = require("biri");
  // const uniqueId = await biri();
  var navigator_info = window.navigator;
  var screen_info = window.screen;
  var uid = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent.replace(/\D+/g, "");
  uid += navigator_info.plugins.length;
  uid += screen_info.height || "";
  uid += screen_info.width || "";
  uid += screen_info.pixelDepth || "";
  console.log(uid);
  return uid;
};
export function getServiceUrl(service) {
  if (service === "viknbooks") {
    console.log("inside viknbooks");
    window.open(`${VIKNBOOKS_FRONT_URL}dashboard/home`, "_self");
  } else if (service === "payroll") {
    console.log("inside payroll", PAYROLL_FRONT_URL);
    window.open(`${PAYROLL_FRONT_URL}dashboard`, "_self");
  } else if (service === "task_manager") {
    console.log("inside task manager");
    window.open(`${TASK_FRONT_URL}`, "_self");
  } else if (service === "admin_panel") {
    window.open(`${ADMIN_FRONT_URL}`, "_self");
  } else {
    window.open(`${ACCOUNTS_FRONT_URL}`, "_self");
  }
}
export const set_RecentLoginUsersCookies = async (data) => {
  let ciphertext = Encrypt(data);
  console.log(ciphertext, "(((((((((((************))))))))))))))))");
  // =======END=============
  let return_value = false;
  await axios
    .get(
      `${ACCOUNTS_API_URL}api/v1/accounts/recent-login-users?data=${ciphertext}`,
      {
        withCredentials: true,
      }
    )
    .then(() => {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let value = params.service;
      // window.location.reload();
      return_value = true;
    });
  return return_value;
};
export const set_handleLogout = async (dic) => {
  document.cookie = `VBID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${domain}`;

  // ==================RECENT USERS==================
  let recent_login_users = getCookie("ACCOUNT_CHOOSER");

  // let dic = {
  //   username: username,
  //   email: email,
  //   image_url:
  //     "https://www.api.viknbooks.com/media/profiles/Screenshot_from_2022-02-02_11-45-23.png",
  // };
  let data = [];
  let data_exists = {};
  if (recent_login_users) {
    console.log(data, "###datadata============");
    try {
      data = Decrypt(JSON.parse(recent_login_users));
    } catch (err) {
      data = Decrypt(recent_login_users);
    }
    data_exists = _.find(data, function (o) {
      return o.username === dic["username"];
    });
  }

  if (_.isEmpty(data_exists)) {
    data.push(dic);
    console.log("^^^^^IFFF^^^^^^^^^^^&*");
  } else {
    console.log("^^^^^ELSE^^^^^^^^^^^&*");
  }

  data = data.slice(-2);
  let return_value = await set_RecentLoginUsersCookies(data);

  // if (recent_login_users) {
  //   data = JSON.parse(data);
  // }
  return return_value;
};

// Export report PDF and EXEL
const ExportExcel = function (data, filename) {
  // Convert a binary string to an array buffer
  // let newData = data.map((item) =>
  //   item.data.map((i) => ({
  //     Date: item.date,
  //     Particular: returnVoucherType(i.voucher_type),
  //     Amount: formatNumber(i.amount),
  //     Notes: i.description,
  //   }))
  // );
  // newData = newData.flat();
  // // Calculate the total amount
  // const totalAmount = newData.reduce((total, item) => total + parseFloat(item.Amount), 0);
  // // Add the total amount to the data
  // newData.push({
  //   Date: "Total",
  //   Particular: "",
  //   Amount: AmountFormater(totalAmount),
  //   Notes: "",
  // });
  // const s2ab = (s) => {
  //   const buf = new ArrayBuffer(s.length);
  //   const view = new Uint8Array(buf);
  //   for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  //   return buf;
  // };
  // // Convert the data to a worksheet
  // const worksheet = XLSX.utils.json_to_sheet(newData);
  // // Create a new workbook and append the worksheet to it
  // const workbook = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  // // Write the workbook to a binary string
  // const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  // // Create a blob and download the file
  // const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  // const url = window.URL.createObjectURL(blob);
  // const a = document.createElement("a");
  // a.href = url;
  // a.download = filename + ".xlsx";
  // a.click();
};

const customRound = (num) => {
  const integerPart = Math.floor(num);
  const decimalPart = num - integerPart;

  if (decimalPart >= 0.5) {
    return Math.ceil(num * 10) / 10;
  } else {
    return Math.floor(num * 10) / 10;
  }
};

export function debounce(func, delay) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
}

export function debounce1(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function showAlertBox() {}

export const downloadFile = (
  data,
  filename,
  mime = "application/octet-stream",
  bom
) => {
  const blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  const blob = new Blob(blobData, { type: mime });

  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround
    window.navigator.msSaveBlob(blob);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download");

    // Handle Safari and its popup blocking
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Clean up
    setTimeout(() => {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

export const serializeParams = (obj) => {
  const str = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
    }
  }
  return str.join("&");
};

export const viknAlert = function (objx) {
  store.dispatch(
    openAlertPopUp({
      open: objx.open,
      title: objx.title || "Alert Title", 
      description: objx.description || "Alert Description",
      cancelButtonText: objx.cancelButtonText || "Cancel", 
      confirmButtonText: objx.confirmButtonText || "Confirm", 
      cnfFunction: objx.cnfFunction, 
    })
  );
};
