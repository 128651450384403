import "./sales_return_style.css";
import { Box, Paper, styled, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ClearButton,
  ListButton,
  LoadingButton,
  SaveButton,
} from "../../Components/Utils/StyledButtons";
import { useTranslation } from "react-i18next";
import {
  CustomVoucher,
  InvoiceAutoComplete,
  InvoiceBarCodeTextBox,
  InvoiceDateBox,
  InvoiceLedgerSearch,
  InvoiceTextBox,
  InvoiceVoucherNoTextBox,
} from "../../Components/CommonComponents";
import MyGrid from "./Details";
import {
  CallSalesReturnDetail,
  CallsalesToSalesreturn,
  CallToSalesreturn,
  edit_salesreturn_api,
  get_salesreturn_details_api,
  LedgerDetails,
  view_salesreturn_api,
} from "../../Api/Sales/SalesReturnApis";
import { openSnackbar } from "../../features/SnackBarslice";
import {
  billwise_datas,
  CustomiseTableApi,
  get_pricelist_list_new_api,
  get_product_by_barcode_api,
  get_product_history_api,
  get_single_pricelist_new_api,
  get_stock_of_products_api,
  get_VoucherNo,
  ledger_details_api,
  place_of_supply_api,
  product_search_new_api,
} from "../../Api/CommonApi/CommonApis";
import NotesTextArea from "../../Components/Utils/TextArea";
import { SalesInvoiceCalc, SalesLineCalc, SalesReturnValidation } from "./functions";
import SummaryDetails from "./SummaryDetails";
import BillwiseModal from "../../Components/Utils/BillwiseModal";
import { debounce } from "../../Function/comonFunction";
import { CallEmployees } from "../../Api/Reports/CommonReportApi";
import { AddressModal, HistoryModal } from "../../Components/CommonForms";
import { create_user_address, list_user_address } from "../../Api/User/User";
import BottomNavigationMenu from "../../Components/Utils/BottomNavigationMenu";
import moment from "moment";

const SalesReturn = () => {
  const [t, i18n] = useTranslation("common");

  const location = useLocation();
  const params = new URLSearchParams(window.location.search);

  const is_edit = location.state?.is_edit ?? params.get('is_edit');
  const uniq_id = location.state?.uniq_id ?? params.get('unq_id');

  const dispatch = useDispatch();
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const {
    PriceDecimalPoint,
    EnableBranch,
    EnableProductBatchWise,
    GST,
    VAT,
    CurrencySymbol,
    EnableSalesManInSales,
    PurchasePriceInSales,
    EnableBillwise,
    show_productcode_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_stock_InsearchSale,
    EnableEinvoiceKSA,
    EnableWarehouse
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);

  let user_role_settings = useSelector((state) => state.userRollSettings);
  let salesReturn_permission = user_role_settings.filter((i) => i.name === "Sales Return");
  let sales_discount_perm = salesReturn_permission[0].discount;
  let sales_discount_limit = salesReturn_permission[0].discount_limit? salesReturn_permission[0].discount_limit: 100;
  let sales_save = salesReturn_permission[0].save_permission;
  let sales_purchase_price_perm = salesReturn_permission[0].purchase_price;
  
  let sales_history_permission = user_role_settings.filter((i) => i.name === "Sales History");
  let purchase_history_permission = user_role_settings.filter((i) => i.name === "Purchase History");

  let sales_history_view_permission = sales_history_permission[0].view_permission
  let purchase_history_view_permission = purchase_history_permission[0].view_permission

  const navigate = useNavigate();
  const [IsButtonClick, setIsButtonClick] = useState(false);
  const { Country, State } = useSelector((state) => state.companyDetails);
  const [is_manual_roundoff,setIs_manual_roundoff] = useState(false)
  const [IsBilling, setIsBilling] = useState(false);
  const CompanyState = State

  const [IsSalesHistory, setIsSalesHistory] = useState(false);
  const [IsPurchaseHistory, setIsPurchaseHistory] = useState(false);

  let GeneralSettingsData = {
    EnableSalesManInSales,
    PriceDecimalPoint,
    PurchasePriceInSales,
    EnableBillwise,
    show_productcode_InsearchSale,
    show_stock_InsearchSale,
    EnableProductBatchWise,
    sales_purchase_price_perm,
    show_purchasePrice_InsearchSale,
    EnableEinvoiceKSA
  }

  // let today = new Date();
  // today.setDate(today.getDate());
  // let DefaultDate = today.toISOString().substr(0, 10);
  let today = moment();
  let DefaultDate = today.format("YYYY-MM-DD");
  const TreatmentData = [
    { value: "0", name: "Registered Business - Regular", is_gst: true },
    { value: "1", name: "Registered Business - Composition", is_gst: true },
    { value: "2", name: "Consumer", is_gst: true, default: true },
    { value: "4", name: "Overseas", is_gst: true },
    { value: "5", name: "Special Economic Zone", is_gst: true },
    { value: "6", name: "Deemed Export", is_gst: true },
    { value: "0", name: "Business to Business(B2B)", is_vat: true },
    {
      value: "1",
      name: "Business to Customer(B2C)",
      is_vat: true,
      default: true,
    },
  ];

  let MenuBottomData = [
    {
      name: "Sales Estimate",
      image: "../images/icons/Estimate.svg",
      link: "/list-sales-estimate",
    },
    {
      name: "Sales Order",
      image: "../images/icons/Order.svg",
      link: "dashboard/sales-order-list",
      domain:"books"
    },
    {
      name: "Sales Invoice",
      image: "../images/icons/Invoice.svg",
      link: "/list-sales",
    },
    {
      name: "Sales Return",
      image: "../images/icons/Return.svg",
      link: "/list-sales-return",
    },
  ];
  

  const GST_TreatmentData = TreatmentData.filter((i) => i.is_gst === true);
  const VAT_TreatmentData = TreatmentData.filter((i) => i.is_vat === true);

  const [dataState, setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    deletedData: [],
    AccountLedgerData: [],
    CashList: [],
    BankList: [],
    CountryData: [],
    EmployeeData: [],
    TaxCategoryData: [],
    UserTableData: [],
    WarehouseData: [],
    PlaceOfSupplyData: [],
    ProductList: [],
    ProductCodeList: [],
    BillWiseDatas : [],
    SalesHistory:[],
    PurchaseHistory:[]
  });

  const [state, setState] = useState({
    AccountLedger: null,
    CashAccount: null,
    BankAccount: null,
    Employee: null,
    Warehouse: null,
    Treatment: null,
    PlaceOfSupply: null,
    Date: DefaultDate,
    RefferenceBillNo: "",
    RefferenceBillDate: DefaultDate,
    BillDiscPercent: 0,
    BillDiscAmt: 0,
    CashAmount: 0,
    BankAmount: 0,
    Notes: "",
    //Total
    TotalQty: 0,
    TotalGrossAmt: 0,
    TotalDiscount: 0,
    TotalTax: 0,
    NetTotal: 0,
    TotalTaxableAmount: 0,
    TaxTaxableAmount: 0,
    NonTaxTaxableAmount: 0,
    GrandTotal: 0,
    LedgerValue: "",
    RoundOff:"0",
    BillingAddress:null,
    TotalSGST:0,
    TotalCGST:0,
    TotalIGST:0,
    TaxType:VAT ? "VAT" : "GST Intra-state B2C",
    TaxID:VAT ? 32 : 22,
  });

  //when you adding any field here add in AddLine and clearData function tooo
  let DataListItem = {
    ProductCode: null,
    BarCode: "",
    Product: null,
    Description: "",
    Unit: null,
    UnitList: [],
    Stock: "",
    Qty: "",
    FQty: "",
    Rate: "",
    AvgCost: "",
    Tax:null,
    InclusivePrice: "",
    GrossAmount: "",
    DiscountPerc: "",
    DiscountAmount: "",
    // ExciseTax: "",
    TaxAmount: "",
    Amount: "",
    MRP:"",
    PurchasePrice:"",
    MinimumSalesPrice:"",
    AverageCost:"",
    TaxableAmount:0,
    NonTaxableAmount:0,
    BatchCode:null,
    IGSTAmount : 0,
    IGSTPerc : 0,
    CGSTAmount : 0,
    CGSTPerc : 0,
    SGSTAmount : 0,
    SGSTPerc : 0,

  }
  const [DataList, setDataLists] = useState([DataListItem]);

  const [defaultValue, setDefaultValue] = useState({
    AccountLedger: null,
    CashAccount: null,
    BankAccount: null,
    // PriceCategory: null,
    Warehouse: null,
    Treatment: null,
    PlaceOfSupply: null,
    BillingAddress: null,
    // ShippingTax: null,
  });

  const [Errors, setErrors] = useState({
    field: null,
    message: null,
    error: false,
    indexList: [],
  });

  const [openCustomVoucher, setopenCustomVoucher] = useState(false)
  const [OpenBillWise, setOpenBillWise] = useState(false)
  const [IsBillwise, setBillwise] = useState(false)
  const is_customer = state.AccountLedger?.AccountGroupUnder !== 9 && state.AccountLedger?.AccountGroupUnder !== 8;

  const [tableSettings, setTableSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: false,
    Product: true,
    Description: false,
    Unit: true,
    Qty: true,
    FQty: false,
    Rate: true,
    Tax: true,
    InclusivePrice: false,
    GrossAmount: true,
    DiscountPerc: false,
    DiscountAmount: false,
    TaxAmount: true,
    Amount: true,
    Activity: true,
    BatchCode:true
  });
  const [focusSettings, setFocusSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: true,
    Product: true,
    Description: true,
    Unit: true,
    Qty: true,
    FQty: true,
    Rate: true,
    Tax: true,
    InclusivePrice: true,
    GrossAmount: true,
    DiscountPerc: true,
    DiscountAmount: true,
    TaxAmount: true,
    Amount: true,
    Activity: true,
    BatchCode:true
  });

  const[BillingAddress,setBillingAddress] = useState({
    Attention : "", //Name
    PostalCode : "", //Pincode
    Address1 : "", //Building
    Address2 : "", //Area
    City : "",
    District : "",
    country : null,
    state : null,
    Mobile : "",
    CountryData:[],
    StateData:[],
    BillingAddressList:[],
  }) 
  
  
  function customRound(x) {
  
    if (EnableEinvoiceKSA){    
      const rounded = Math.round(x * 100) / 100;
  
      if (rounded - x === 0.5) {
        return rounded;
      }
  
      return rounded;
    }
    else{
      return Number(x)
    }
  }
  

  const AddLine = () => {
    let Data = DataList;
    let newData = DataListItem;
    Data.push(newData);
    setDataLists([...Data]);
  };
  

  const MasterCalcFields = [
    "BillDiscPercent",
    "BillDiscAmt",
    "CashAmount",
    "BankAmount",
    "RoundOff"
  ];

  const onMasterChange = (e) => {
    if(e){
      const { name, value } = e.target;
      
      //checking the field is included in MasterCalcFields
      const isCalcField = MasterCalcFields.includes(name);
      
      if (isCalcField){
        state[name] = value
        
        
        //handling bill disc 
        if (name === "BillDiscAmt"){
          state.BillDiscPercent = 100 * (Number(value)/Number(state.NetTotal))
        }
        else if (name === "BillDiscPercent"){
          state.BillDiscAmt = (Number(value)/100) * Number(state.NetTotal)
        }
        
            
        let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff,customRound,GST,CompanyState);
        setState({ ...newState });
      }
      else{
        setState((prevState) => ({ ...prevState, [name]: value }));
      } 
    }
    
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const CustomVoucherSet = (e) => {
    let PreFix = dataState.PreFix;
    let Seperator = dataState.Seperator;
    let InvoiceNo = dataState.InvoiceNo;
    let SuffixSeparator = dataState.SuffixSeparator?dataState.SuffixSeparator:"";
    let Suffix = dataState.Suffix?dataState.Suffix:"";
    let VoucherNo = String(PreFix) + String(Seperator) + String(InvoiceNo) +String(SuffixSeparator) +String(Suffix);
    setDataState({ ...dataState, VoucherNo: VoucherNo });
    setopenCustomVoucher(false);
  }

  const openBillingAddressModal  = async() =>{
    setIsBilling(true)
    let res = await Create_BillingAddress(BillingAddress,"list")
    if (res){
        setBillingAddress({...BillingAddress,BillingAddressList:res})
    }
  }

  const onMasterInputChange = async (search, name) => {
    if (search && name) {
      let length = search.length;
      if (name === "AccountLedger") {
        const LedgerList = await LedgerDetails({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          ledger_name: search,
          length: length,
          load_data: false,
          type_invoice: "SalesInvoice",
        });
        console.log(LedgerList);
        if (LedgerList.StatusCode === 6000) {
          let AccountLedgerData = [...LedgerList.data];
          setDataState({ ...dataState, AccountLedgerData: AccountLedgerData });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: LedgerList?.message ?? "Api Error",
              severity: "error",
            })
          );
        }
      } else if (name === "Employee") {
        const EmployeeList = await Call_Employees_api({
          CompanyID : CompanyID,
          BranchID : BranchID,
          CreatedUserID : user_id,
          PriceRounding : Number(PriceDecimalPoint || 2),
          search : search,
          list_type : "sales",
        })

        const uniqueSalesmansData = EmployeeList.filter((salesman) => {
          // Check if the salesman id is not already in EmployeesList
          return !dataState.EmployeeData.some((employee) => employee.id === salesman.id);
        });
          let EmployeeData = [...dataState.EmployeeData,...uniqueSalesmansData]
          setDataState({...dataState,EmployeeData:EmployeeData})
      }
    }
  };

  const onMasterAutoChange = async(e, v, name) => {
    if(v && name){
      const isCalcField = MasterCalcFields.includes(name);
      let value = v
      if (name === "AccountLedger"){
        let {AccountLedger,BillingAddress_object} = await Call_LedgerDetials(value)
        console.log(AccountLedger,'AAAAAAAAAAACCCCCCCCCCCCCCCCCCCCCC',value);
        let data = AccountLedger
        let PlaceOfSupplyData;
        let CountryID = Country
        if (data.CountryID){
          CountryID = data.CountryID
        }
        const PlaceOfSupplyResponse = await place_of_supply_api(CountryID);
        if (PlaceOfSupplyResponse.StatusCode === 6000) {
          PlaceOfSupplyData = PlaceOfSupplyResponse.data;
        }
        console.log(data,"data,BillingAddress_object",BillingAddress_object);
        let Treatment = null;
        if(VAT) {
          Treatment = VAT_TreatmentData.find((i)=> i.value === data.Treatment)
        } else {
          Treatment = GST_TreatmentData.find((i)=> i.value === data.Treatment)
        }
        let stateID = CompanyState
        if(data.State_id){
          stateID = data.State_id
        }
        let PlaceOfSupply = PlaceOfSupplyData.find((i) => i.id === stateID)
        console.log(data.State_id,'lDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD');
        if ((VAT && (Treatment === null || Treatment === undefined))) {
          Treatment = {value: "1", name: 'Business To Customer (B2C)'};
        } else if ((GST && (Treatment === null || Treatment === undefined))) {
            Treatment = {value: "2", name: 'Consumer'};
        }

        let [NewTaxType,NewTaxID] = await Get_TaxType_TaxID(Treatment.value,PlaceOfSupply.id)
        console.log(PlaceOfSupply,'placeofsup[play');
        
        // let PriceCategory = dataState.PriceCategoryData.find((i)=>i.PriceCategoryID === 1)
        // if (data.PriceCategoryID){
        //   PriceCategory = dataState.PriceCategoryData.find((i)=>i.PriceCategoryID === data.PriceCategoryID)
        // }
        setDataState({
          ...dataState,
          PlaceOfSupplyData : PlaceOfSupplyData
        })
        setState({...state,[name]:data,Treatment,PlaceOfSupply,ShippingAddress:null,BillingAddress:BillingAddress_object,TaxType:NewTaxType,TaxID:NewTaxID})
      }
            
      else if (name === "Warehouse"){
        let Data = DataList
        let payload = DataList.map(object => object?.Unit?.PriceListID);
        let StockData = await Call_Stock_api({
          CompanyID: CompanyID,
          BranchID: BranchID,
          PriceListIDList : payload ,
          WarehouseID:value.WarehouseID
        })
        
        Data.map((lineItem,index)=>{
          if (lineItem.Product){            
            lineItem.Stock =  StockData.find((i)=> i.PriceListID === lineItem.Unit.PriceListID)?.Stock ?? 0
            Data[index] = lineItem
          }
        }) 
        
        setDataLists([...Data]);
        setState({...state,[name]:value})
        
        
      }

      else if (name === "Treatment") {
        let Treatment = value;
        const {TaxType , TaxID} = state
        let PlaceOfSupply = state.PlaceOfSupply?.id;
        let [NewTaxType,NewTaxID] = await Get_TaxType_TaxID(Treatment.value,PlaceOfSupply)

        setState({
          ...state,
          TaxType:NewTaxType,
          TaxID:NewTaxID,
          Treatment:Treatment,
        })
      }
      
      else if (name === 'PlaceOfSupply') {
        if (value){
          let PlaceOfSupply = value;
          let TaxType = state.TaxType;
          let TaxID = state.TaxID;

          if(CompanyState === PlaceOfSupply?.id && state.Treatment?.value !="5" ){
            if(TaxID === 23){
              TaxType = "GST Intra-state B2B";
              TaxID = 21;
            } else if(TaxID === 24) {
              TaxType = "GST Intra-state B2C";
              TaxID = 22;
            }
          } else {
            if (TaxID === 21) {
              TaxType = "GST Inter-state B2B";
              TaxID = 23;
            } else if (TaxID === 22) {
              TaxType = "GST Inter-state B2C";
              TaxID = 24;
            }
          }
          
          setState({
            ...state,
            PlaceOfSupply: PlaceOfSupply,
            TaxType : TaxType,
            TaxID : TaxID,
          })
        } else {
          setState({...state,[name]:value})
        }
      }

      else if(isCalcField){
        state[name] = value
        let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff,customRound,GST,CompanyState);
        setState({ ...newState });
      }
      else{
        setState({...state,[name]:value})
      }
    }  
  }

  const onDetailsInputChange = async (e, name) => {
    if (e && name) {
      let value = e.target.value;
      if ((name === "Product" || name === "ProductCode") && value) {
        const ProductList = await Call_ProductSearchFunction({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state?.Warehouse.WarehouseID,
          is_product_image: false,
          length: value?.length,
          product_name: value,
          type: "SalesReturn",
        });
        setDataState({ ...dataState, ProductList: ProductList });
      }
    }
  };

  const onDetailsChange = async(e,index) =>{
    if (e){
      let value = e.target.value;
      let name = e.target.name;
      let Data = DataList;
      console.log(name,value,"name,value:LLLLLLLLLLLLLLL:::::::::::::::::::::::::::");
      let lineItem = Data[index]
      lineItem[name] = value
    
      // handling Line Discount
      let Amount = lineItem.GrossAmount 
      if (name === "DiscountAmount"){
        lineItem.DiscountPerc = 100 * (Number(value)/Number(Amount))
      } else if (name === "DiscountPerc"){
        lineItem.DiscountAmount = (Number(value)/100) * Number(Amount)
      } 
      else if (name === "InclusivePrice"){
        lineItem.Rate = Number(value) / (1 + (lineItem.Tax?.SalesTax || 0) / 100);
      }


      let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound,GST,CompanyState)
      
      Data[index] = calculated_lineItem
      setDataLists([...DataList])
    }
  }

  const RemoveLine = (index) => {
    let Data = DataList;
    let deletedData = [];
    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        if (is_edit) {
          deletedData.push(Data[index]);
        }

        Data.splice(index, 1);
        
      }
    }
    
    let newState = SalesInvoiceCalc(dataState,state, Data,is_manual_roundoff,customRound,GST,CompanyState);
    setState({ ...newState,deletedData:deletedData });
    
    setDataLists([...Data]);
  };


  const Get_TaxType_TaxID = async (GST_Treatment, PlaceOfSupply) => {
    let { TaxType, TaxID, Customer_state_Code: CustomerStateCode } = state;
    let company_state = CompanyState;
  
    PlaceOfSupply = PlaceOfSupply || company_state;
  
    if (GST) {
      switch (GST_Treatment) {
        case "0":
        case "1":
          if (company_state === PlaceOfSupply) {
            TaxType = "GST Intra-state B2B";
            TaxID = 21;
          } else {
            TaxType = "GST Inter-state B2B";
            TaxID = 23;
          }
          break;
  
        case "5":
          TaxType = "GST Inter-state B2B";
          TaxID = 23;
          break;
  
        case "3":
        case "2":
          if (company_state === PlaceOfSupply) {
            TaxType = "GST Intra-state B2C";
            TaxID = 22;
          } else {
            TaxType = "GST Inter-state B2C";
            TaxID = 24;
          }
          break;
  
        case "4":
        case "6":
          TaxType = "Export";
          TaxID = 25;
          break;
  
        default:
          GST_Treatment = "2";
          if (company_state === PlaceOfSupply) {
            TaxType = "GST Intra-state B2C";
            TaxID = 22;
          } else {
            TaxType = "GST Inter-state B2C";
            TaxID = 24;
          }
      }
    } else if (VAT) {
      TaxType = "VAT";
      TaxID = 32;
    }
  
    return [TaxType, TaxID, GST_Treatment];
  };
  


  //Ledger Searching api
  const Call_LedgerDetials = async (data) => {
    const LedgerDetails = await ledger_details_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      ledger_id: data?.id,
      PriceRounding: PriceDecimalPoint,
    });

    if (LedgerDetails.StatusCode === 6000) {
      console.log(LedgerDetails,"LLLLLLLLLLLLLEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE>>>>>>>>>>>>>>>>>>>.");
      let AccountLedger = data; //state.AccountLedger
      AccountLedger["Treatment"] = LedgerDetails.data.Treatment;
      AccountLedger["TaxNo"] = LedgerDetails.data.TaxNo;
      AccountLedger["Mobile"] = LedgerDetails.data.Mobile;
      AccountLedger["BillingAddress"] = LedgerDetails.data.BillingAddress;
      AccountLedger["RouteName"] = LedgerDetails.data.RouteName;
      AccountLedger["Balance"] = LedgerDetails.data.Balance;

      let BillingAddress_object = LedgerDetails.data.BillingAddress_object
      
      // setState({...state,AccountLedger:AccountLedger})
      Call_BillwiseDetails(AccountLedger)
      return {AccountLedger,BillingAddress_object}
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: LedgerDetails?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
  };


  //get states of a give country api  
  const Call_States_of_Country_api = async(payload) =>{
    let data = null
    const Response = await place_of_supply_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }


    //==============CREATE/UPDATE BILLING ADDRESS==================
    const Create_BillingAddress = async(payload,method) =>{
    
      if (method === "list"){
        payload.CompanyID = CompanyID
        payload.BranchID=BranchID
        payload.LedgerID=state.AccountLedger.LedgerID
        payload.Type = "BillingAddress"	
        const list_resposne = await list_user_address({
        CompanyID : CompanyID,
        BranchID:BranchID,
        LedgerID:state.AccountLedger.LedgerID,
        Type : "BillingAddress"	,
        })
        if (list_resposne.StatusCode===6000){
          return list_resposne.data
        }
      }
      else if(
        !payload.Attention||
        !payload.PostalCode||
        !payload.Address1||
        !payload.Address2||
        !payload.City||
        !payload.District||
        !payload.country||
        !payload.state||
        !payload.Mobile
      ){
        dispatch(
          openSnackbar({
            open: true,
            message: "Please fill all mandatory fields",
            severity: "warning",
          })
        );
      }
      else if (method==="create"){
        
        payload.CompanyID = CompanyID
        payload.BranchID=BranchID
        payload.LedgerID=state.AccountLedger.LedgerID
        payload.type_data = "create"
        payload.AddressType = "BillingAddress"
        
        payload.country = payload.country.id
        payload.state = payload.state.id
  
      
        const responseData = await create_user_address(payload)
          
        if (responseData.StatusCode === 6000) {
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Created Successfully",
              severity: "success",
            })
          );
          
          setBillingAddress({...BillingAddress,BillingAddressList:responseData.data})
          return true
        } else if (responseData.StatusCode === 6001) {
    
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "warning",
            })
          );
          return false
        } 
        else if (responseData.StatusCode === 6002) {
       
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
              severity: "error",
            })
          );
          return false
        } 
        else {
    
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
              severity: "error",
            })
          );
          return false
        }
      }
      
    }



  // Call Billwise Customer 
  const Call_BillwiseDetails = async (data) => {
    try {
      if (!uniq_id && !is_edit) {
        const billWiseDataResponse = await billwise_datas({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          LedgerID: data?.LedgerID ?? 0,
          PaymentVoucherNo: dataState.VoucherNo,
          PaymentVoucherType: "SI",
          PriceRounding: PriceDecimalPoint,
          call_type: "list_type",
        });
    
        setDataState((prevState) => ({
          ...prevState,
          BillWiseDatas: billWiseDataResponse.StatusCode === 6000 ? billWiseDataResponse.data : [],
        }));
        if (billWiseDataResponse?.data?.length) {
          setBillwise(true);
        } else {
          setBillwise(false);
        }
      } else {
        console.log("Passs");
      }
    } catch (error) {
      console.error("Error fetching billwise details:", error);
      setDataState({
        ...dataState,
        BillWiseDatas: [],
      });
    }
  };
  



  // const handleChangeBillwiseDetails = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedValue = Number(value); // Convert value to a number
    
  //   setDataState(prevState => {
  //     const updatedBillWiseDatas = [...prevState.BillWiseDatas];
      
  //     // Update the specific field for the given index
  //     updatedBillWiseDatas[index] = {
  //       ...updatedBillWiseDatas[index],
  //       [name]: updatedValue
  //     };

  //     return {
  //       ...prevState,
  //       BillWiseDatas: updatedBillWiseDatas
  //     };
  //   });
  // };
  
  const CheckBillwiseAmount = (e) => {
    let BillwiseDetails = [...dataState.BillWiseDatas];
    let AmountSum = BillwiseDetails.reduce((sum, o) => sum + Number(o.Amount), 0);

    let GrandTotal = Number(state.GrandTotal);
    if (GrandTotal >= AmountSum) {
      setBillwise(false);
      setOpenBillWise(false);
      handleSubmit(e,false);
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: "Total amount of billwise against must be equal to grand total",
          severity: "warning",
        })
      );
    }
  }; 

  const handleChangeBillwiseDetails = async (e,index) => {
    const { name, value, type, checked } = e.target; 

    let BillWiseDatas = [...dataState.BillWiseDatas];
    if (name === "Amount"){
      let InvoiceAmount = BillWiseDatas[index]["InvoiceAmount"]; // grand Total
      let Payments = BillWiseDatas[index]["Payments"];
      let Amount2 = BillWiseDatas[index]["Amount2"];
      let checkAmount = (Number(Math.abs(InvoiceAmount))-Number(Math.abs(Payments))+Number(Math.abs(Amount2)))
      if (Number(Math.abs(checkAmount)) >= Number(value)) {
        BillWiseDatas[index]["Amount"] = value;
      }
    } else if (name === "full_amt") {
      const BoxChecked = type === 'checkbox' ? checked : Number(value);
      BillWiseDatas[index][name] = checked;
      let AmountDue = 0;
      let Amount2 = BillWiseDatas[index]["Amount2"];

      AmountDue = BillWiseDatas[index]["AmountDue"];
      if (BoxChecked)
        BillWiseDatas[index]["Amount"] = Number(Amount2)+Number(AmountDue);
      else{
        BillWiseDatas[index]["Amount"] = 0;
      }
    } else {
      BillWiseDatas[index][name] = Number(value);
    }
    setDataState((prevState) => {
      return {
        ...prevState,
        BillWiseDatas,
      }
    })
  }


  const Call_Stock_api = async(payload) =>{
    let data = null
    const Response = await get_stock_of_products_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }


  // Product UnitList(PriceList) list api
  const Call_PriceList_list_Function = async (payload) => {
    let data = [];
    const Response = await get_pricelist_list_new_api(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  //Detials of selected unit(pricelist) api
  const Call_Single_PriceList_Function = async (payload) => {
    let data = null;
    const Response = await get_single_pricelist_new_api(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  // Product Search API
  const Call_ProductSearchFunction = async (payload) => {
    let data = [];
    const Response = await product_search_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ? Response.message : "API error",
          severity: "error",
        })
      );
    }
    return data;
  };

  const onDetailsAutoChange = async (v, index, name) => {
    console.log(v, name, "333333333");
    if (v && name && v?.id) {
      let Data = DataList;
      let lineItem = Data[index];

      if (name === "Product" || name === "ProductCode") {
        let Product = v;
        let UnitList = await Call_PriceList_list_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          product_id: Product.id,
          type_data: "SI",                     //______________----------------------------------------------------------------------------------------------------------------------------
        });

        let Unit = UnitList.find((i) => i.UnitInSales === true);
        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.Warehouse.WarehouseID,
        });

        let Rate = 0;
        let Qty = 1;
        let FQty = 0;
        let DiscountPerc = 0;
        let DiscountAmount = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.SalesPrice);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
        }

        if (EnableProductBatchWise === true){
          if (UnitDetails.BatchCode){
            lineItem.Rate = UnitDetails.BatchCode.SalesPrice
            lineItem.PurchasePrice = UnitDetails.BatchCode.PurchasePrice
            lineItem.Stock = lineItem.BatchCode.Stock
          }
        }

        let Tax = dataState.TaxCategoryData.find(
          (i) => i.TaxID === Product.ProductTaxID
        );

        lineItem.Product = Product;
        lineItem.ProductCode = Product;
        lineItem.Description = Product.Description;
        lineItem.Stock = Product.Stock;
        lineItem.Qty = Qty;
        lineItem.FQty = FQty;
        lineItem.Tax = Tax;
        lineItem.DiscountPerc = DiscountPerc;
        lineItem.DiscountAmount = DiscountAmount;

        lineItem.Unit = Unit;
        lineItem.UnitList = UnitList;

        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound,
          GST,
          CompanyState
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      } else if (name === "Unit") {
        let Unit = v;

        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.Warehouse.WarehouseID,
        });
        let Rate = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.SalesPrice);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
        }
        lineItem.Unit = Unit;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound,
          GST,
          CompanyState
        );

        Data[index] = calculated_lineItem;
        setDataLists([...Data]);
      } else if (name === "Tax") {
        let Tax = v;
        lineItem.Tax = Tax;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound,
          GST,
          CompanyState
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      }

      else if (name === "BatchCode"){
        let BatchCode = v
        lineItem.BatchCode = BatchCode
        lineItem.Rate = BatchCode.SalesPrice
        lineItem.PurchasePrice = BatchCode.PurchasePrice
        lineItem.Stock = BatchCode.Stock
        let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound,GST,CompanyState)
        
        Data[index] = calculated_lineItem
        
        setDataLists([...Data]);
      }
    }
  };

  // initial APIS AND SETTING DATA ==========/
  console.log('ODDDDDDDDDDSSSSSSSSSSSSSSSSS55555555555555555555SSSS',GST,'vattt',VAT);
  const fetchData = async () => {
    let ResponseView = null
    let AccountLedgerData = [];
    let CashList = [];
    let BankList = [];
    let CountryData = [];
    let EmployeeData = [];
    let PriceCategoryData = [];
    let TaxCategoryData = [];
    let UserTableData = [];
    let WarehouseData = [];
    let Seperator = "";
    let Suffix = "";
    let SuffixSeparator = "";
    let PreFix = "";
    let InvoiceNo = "";
    let VoucherNo = "";
    let PlaceOfSupplyData = [];
    let BillWiseDatas = [];

    const Data = await CallSalesReturnDetail({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
    });

    if (Data.StatusCode === 6000) {
      const PlaceOfSupplyResponse = await place_of_supply_api(Country);
      if (PlaceOfSupplyResponse.StatusCode === 6000) {
        PlaceOfSupplyData = PlaceOfSupplyResponse.data;
      }

      AccountLedgerData = Data.AccountLedgerData;
      let CashAndBankData = Data.CashAndBankData;
      if (CashAndBankData) {
        CashList = CashAndBankData.filter((i) => i.AccountGroupUnder === 9);
        BankList = CashAndBankData.filter((i) => i.AccountGroupUnder === 8);
      }

      CountryData = Data.CountryData;
      EmployeeData = Data.EmployeeData;
      PriceCategoryData = Data.PriceCategoryData;
      TaxCategoryData = Data.TaxCategoryData;
      UserTableData = Data.UserTableData;
      WarehouseData = Data.WarehouseData;

      let VoucherNoGenerateTableData = Data.VoucherNoGenerateTableData;
      Seperator = VoucherNoGenerateTableData.Seperater;
      Suffix = VoucherNoGenerateTableData.Suffix;
      SuffixSeparator = VoucherNoGenerateTableData.SuffixSeparator;
      PreFix = VoucherNoGenerateTableData.PreFix;
      InvoiceNo = VoucherNoGenerateTableData.InvoiceNo;
      VoucherNo = VoucherNoGenerateTableData.VoucherNo;

      //Default Values
      let AccountLedgerDefault = AccountLedgerData.find((i) => i.is_default === true);
      let Warehouse = WarehouseData.find(
        (i) => i.WarehouseID === UserTableData.DefaultWarehouse
      );
      let Treatment = Data.VAT
        ? VAT_TreatmentData.find((i) => i.default === true)
        : Data.GST
        ? GST_TreatmentData.find((i) => i.default === true)
        : null;
      let PlaceOfSupply = PlaceOfSupplyData.find((i) => i.id === State);
      let PriceCategory = PriceCategoryData.find(
        (i) => (i.PriceCategoryID = 1)
      );
      let CashAccount = CashList[0];
      let BankAccount = BankList[0];
      // let ShippingTax = TaxCategoryData.find((i)=> i.TaxName === "None")

      AccountLedgerDefault = AccountLedgerData.find((i) => i.is_default === true);
      Warehouse = WarehouseData.find(
        (i) => i.WarehouseID === UserTableData.DefaultWarehouse
      );
      // Treatment = VAT
      //   ? VAT_TreatmentData.find((i) => i.default === true)
      //   : GST
      //   ? GST_TreatmentData.find((i) => i.default === true)
      //   : null;
      PlaceOfSupply = PlaceOfSupplyData.find((i) => i.id === State);
      PriceCategory = PriceCategoryData.find((i) => (i.PriceCategoryID = 1));
      CashAccount = CashList[0];
      BankAccount = BankList[0];
      // ShippingTax = TaxCategoryData.find((i)=> i.TaxName === "None")
      let [NewTaxType,NewTaxID] = await Get_TaxType_TaxID(Treatment.value,PlaceOfSupply.id)

      let customise_tale = await CustomiseTableApi({
        CompanyID : CompanyID,
        BranchID : BranchID,
        UserID : user_id,
        VoucherType : "SR",
        SettingsName : '',
        SettingsValue : '',
        Type : 'List'
      })
      const res = customise_tale.datas.reduce((acc, curr) => {
        acc[curr.SettingsName] = curr.SettingsValue;
        return acc;
      }, {});
      console.log(res,'ressssssssss');
      
      setTableSettings((prev) => ({
        ...prev,
        BarCode: res.is_barcode ?? prev.BarCode,
        Unit: res.is_unit ?? prev.Unit,
        Description: res.is_description ?? prev.Description,
        DiscountAmount: res.is_discount_amount ?? prev.DiscountAmount,
        Rate: res.is_rate ?? prev.Rate,
        Qty: res.is_qty ?? prev.Qty,
        ProductCode: res.is_product_code ?? prev.ProductCode,
        DiscountPerc: res.is_discount_percentage ?? prev.DiscountPerc,
        FQty: res.is_free_qty ?? prev.FQty,
        BatchCode: res.is_batchcode ?? prev.BatchCode,
        Tax: res.is_tax ?? prev.Tax,
        GrossAmount: res.is_gross_amount ?? prev.GrossAmount,
        Amount: res.is_amount ?? prev.Amount,
        Product: res.is_name ?? prev.Product,
        TaxAmount: res.TaxAmount ?? prev.TaxAmount,
        InclusivePrice: res.is_inclusive_tax ?? prev.InclusivePrice
      }));
      
      setFocusSettings((prev) => ({
        ...prev,
        BarCode: res.is_barcode_focus ?? prev.BarCode,
        Unit: res.is_unit_focus ?? prev.Unit,
        Description: res.is_description_focus ?? prev.Description,
        DiscountAmount: res.is_discount_amount_focus ?? prev.DiscountAmount,
        Rate: res.is_rate_focus ?? prev.Rate,
        Qty: res.is_qty_focus ?? prev.Qty,
        ProductCode: res.is_product_code_focus ?? prev.ProductCode,
        DiscountPerc: res.is_discount_percentage_focus ?? prev.DiscountPerc,
        FQty: res.is_free_qty_focus ?? prev.FQty,
        BatchCode: res.is_batchcode_focus ?? prev.BatchCode,
        Tax: res.is_tax_focus ?? prev.Tax,
        GrossAmount: res.is_gross_amount_focus ?? prev.GrossAmount,
        Amount: res.is_amount_focus ?? prev.Amount,
        Product: res.is_name_focus ?? prev.Product,
        TaxAmount: res.is_tax_amount_focus ?? prev.TaxAmount,
        InclusivePrice: res.is_inclusivePrice_focus ?? prev.InclusivePrice
      }));

      setDefaultValue((prev) => {
        return {
          ...prev,
          AccountLedger: AccountLedgerDefault,
          CashAccount: CashAccount,
          BankAccount: BankAccount,
          PriceCategory: PriceCategory,
          Warehouse: Warehouse,
          Treatment: Treatment,
          PlaceOfSupply: PlaceOfSupply,
          // ShippingTax:ShippingTax,
        };
      });

      let BillWiseDataResponse = Call_BillwiseDetails(AccountLedgerDefault)
      if (BillWiseDataResponse.StatusCode === 6000) {
        BillWiseDatas = BillWiseDataResponse.data
      }

      //calling single view api
      if (is_edit && uniq_id){
        ResponseView = await Call_SalesReturnMasterView({BranchID:BranchID,CompanyID:CompanyID,"uniq_id":uniq_id})
        console.log(ResponseView,"resssspppppppppppppoooooooooooo");
        if (ResponseView){
          let {AccountLedger,BillingAddress_object} = await Call_LedgerDetials(ResponseView.AccountLedger)
          AccountLedgerDefault=AccountLedger  
          Warehouse = ResponseView.Warehouse
          Treatment = ResponseView.Treatment
          PlaceOfSupply = ResponseView.PlaceOfSupply
          CashAccount = ResponseView.CashAccount
          BankAccount = ResponseView.BankAccount
          BillWiseDatas = ResponseView.BillwiseDetails
          NewTaxType = ResponseView.TaxType
          NewTaxID = ResponseView.TaxID

          if (ResponseView.BillwiseDetails?.length) {
            setBillwise(true)
          } else {
            setBillwise(false)
          }

          VoucherNo = ResponseView.VoucherNo
          setIs_manual_roundoff(ResponseView.is_manual_roundoff)

          // Calling details in mutiple for reduce
          let TotalQty = Number(ResponseView.TotalQty)
          let noOfPages = Math.ceil(TotalQty / 100);

          let data = []
          for (let i=1;i<=noOfPages;i++){
            let DetailsReponse = await Call_sales_details({BranchID:BranchID,CompanyID:CompanyID,"uniq_id":uniq_id,page:i})
            console.log(DetailsReponse,"kkkkkkkkkkkooOOOOOOSD");
            
            if (DetailsReponse){
              data = [...data,...DetailsReponse]
            }
          }

          setDataLists([...data])
          // setDataState((prev) => ({
          //   ...prev,
          //   BillWiseDatas: BillWiseDatas,
          // }));
          
          console.log(BillWiseDatas,"resssspppppppppppppooooooooooooBillwiseDAtaaaaaaaa",dataState.BillWiseDatas);

        }
      }

      setState((prev)=>{
        return({...prev,
          ...ResponseView,
          AccountLedger:AccountLedgerDefault,
          Warehouse,
          Treatment,
          PlaceOfSupply,
          CashAccount,
          BankAccount,
          TaxID:NewTaxID,
          TaxType : NewTaxType,
        })      
      })

      setDataState((prev) => {
        return ({
          ...prev,
          AccountLedgerData,
          CashList,
          BankList,
          CountryData,
          EmployeeData,
          PriceCategoryData,
          TaxCategoryData,
          UserTableData,
          WarehouseData,
          Seperator,
          Suffix,
          SuffixSeparator,
          PreFix,
          InvoiceNo,
          VoucherNo,
          PlaceOfSupplyData,
          BillWiseDatas
        });
      });

      setBillingAddress({...BillingAddress,CountryData,StateData:PlaceOfSupplyData})

    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Data?.message ?? "get salesreturn Api Error",
          severity: "error",
        })
      );
    }
  };


    //BarCode Api calling
    const Call_Barcode_api = async(payload) =>{
      let data = null
      const Response = await get_product_by_barcode_api(payload)
      console.log(Response,"Response");
      if (Response.StatusCode === 6000){
        data = Response.data
      }
      else if (Response.StatusCode === 6001){
        data = null
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }

    // search employee
    const Call_Employees_api = async(payload) =>{
      let data = []
      const Response = await CallEmployees(payload)
      console.log(Response,"Response");
      if (Response.StatusCode === 6000){
        data = Response.data
      }
      else if (Response.StatusCode === 6001){
        data = []
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Seles Man Search Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }
  

  
  // Change table Custamization
  const ChangeTableCustomization = async (type, name) => {
    console.log(type, "typeee", name);
    let new_name = name;
    let value = null;
  
    // Mapping for table settings and focus settings
    const nameMapping = {
      BarCode: "is_barcode",
      Unit: "is_unit",
      Description: "is_description",
      DiscountAmount: "is_discount_amount",
      Rate: "is_rate",
      Qty: "is_qty",
      ProductCode: "is_product_code",
      DiscountPerc: "is_discount_percentage",
      FQty: "is_free_qty",
      BatchCode: "is_batchcode",
      Tax: "is_tax",
      GrossAmount: "is_gross_amount",
      Amount: "is_amount",
      Product: "is_name",
      TaxAmount: "TaxAmount",
      InclusivePrice:"is_inclusive_tax",
    };
  
    // Mapping for focus settings
    const focusMapping = {
      BarCode: "is_barcode_focus",
      Unit: "is_unit_focus",
      Description: "is_description_focus",
      DiscountAmount: "is_discount_amount_focus",
      Rate: "is_rate_focus",
      Qty: "is_qty_focus",
      ProductCode: "is_product_code_focus",
      DiscountPerc: "is_discount_percentage_focus",
      FQty: "is_free_qty_focus",
      BatchCode: "is_batchcode_focus",
      Tax: "is_tax_focus",
      GrossAmount: "is_gross_amount_focus",
      Amount: "is_amount_focus",
      Product: "is_name_focus",
      TaxAmount: "is_tax_amount_focus",
      InclusivePrice: "is_inclusivePrice_focus",
    };
  
    if (type === "tableSettings") {
      // Use the mapping for table settings
      new_name = nameMapping[name] || name;
      value = !tableSettings[name];
      setTableSettings({
        ...tableSettings,
        [name]: value
      });
    } else {
      // Use the mapping for focus settings
      new_name = focusMapping[name] || name;
      value = !focusSettings[name];
      setFocusSettings({
        ...focusSettings,
        [name]: value
      });
    }
  
    // Call the API with the updated settings
    await CustomiseTableApi({
      CompanyID: CompanyID,
      BranchID: BranchID,
      UserID: user_id,
      VoucherType: "SR",
      SettingsName: new_name,
      SettingsValue: value,
      Type: "changes"
    });
  };
  


  
  // Sales to Sales Return API on (From Sales)
  const CallFromSales = async (payload) => {
    let data = null;
    const response = await CallsalesToSalesreturn(payload)
    if (response.StatusCode === 6000) {
      data = response.data;
    }
    else if (response.StatusCode === 6001){
      data = null
      dispatch(
        openSnackbar({
          open: true,
          message : response?.message ?? "API error",
          severity: "warning",
        })
      )
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message : response?.message ?? "API error",
          severity: "error",
        })
      )
    }
    return data
  }

//  single View (Master)
  const Call_SalesReturnMasterView = async (payload) => {
    let data = []
    const Response = await view_salesreturn_api(payload)
    if (Response.StatusCode === 6000) {
      data = Response.data
    } else if (Response.StatusCode === 6001) {
      data = []
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message : Response?.message ?? "An error occurred"
        })
      )
    }

    return data
  }
  
  // SalesReturn details view
  const Call_sales_details = async(payload) => {
    let data = null
    const Response = await get_salesreturn_details_api(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }

  
  const Call_Product_History_api = async(payload) =>{
    let data = []
    let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
    if (ProductIDs.length > 0){          
      let LastProduct =  ProductIDs[ProductIDs.length - 1].Product?.ProductID
      payload.CompanyID = CompanyID
      payload.BranchID = BranchID
      payload.CreatedUserID = user_id
      payload.LedgerID = state.AccountLedger.LedgerID
      payload.PriceRounding = 2
      payload.ProductIDs = [LastProduct]
    }
    
    const Response = await get_product_history_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.history_datas
    }
    else if (Response.StatusCode === 6001){
      data = []
      dispatch(
        openSnackbar({
          open: true,
          message: "No More Data!",
          severity: "warning",
        })
      );
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }
  




  const AddSalesReturn = async (VoucherNo) => {
    let Data = DataList
    let State = state
    let lineItem = DataListItem
    let ReturnData = await CallFromSales({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint ?? 2),
      VoucherNo : VoucherNo
    })
    
    if (ReturnData) {
      let Details = ReturnData.SalesDetails;
      State.Employee = ReturnData.Employee;
      let {AccountLedger,BillingAddress_object} = await Call_LedgerDetials(ReturnData.LedgerList[0])
      State.AccountLedger = AccountLedger
      State.RefferenceBillNo = ReturnData.RefferenceBillNo
      State.RefferenceBillDate = ReturnData.Date
      State.Notes = ReturnData.Notes
      State.BillDiscAmt = ReturnData.BillDiscAmt
      State.BillDiscPercent = ReturnData.BillDiscPercent
      State.BankAmount = ReturnData.BankAmount
      State.CashAmount = ReturnData.CashAmount
      // State.NetTotal = ReturnData.NetTotal
      console.log(ReturnData,"returnnnnnnnnnnnndataaaaaaaaaaaaa");
      
            
      if (Details) {
        const updatedDataList = Details.map((detail) => {
          const Tax = dataState.TaxCategoryData.find((i) => i.TaxID === detail.ProductTaxID);
          let Unit = detail.UnitList.find((i) => i.PriceListID === detail.PriceListID);
          console.log(Unit,"unittt");
          

          return {
            ...DataListItem,
            ProductCode: detail,
            BarCode: detail.Barcode,
            Product: detail.Product[0],
            Description: detail.Description,
            Unit: Unit,
            UnitList: detail.UnitList,
            Stock: detail.Stock,
            Qty: detail.Qty,
            FQty: detail.FreeQty,
            Rate: detail.UnitPrice,
            AvgCost: detail.AvgCost,
            Tax: Tax, 
            InclusivePrice: detail.InclusivePrice,
            GrossAmount: detail.GrossAmount,
            DiscountPerc: detail.DiscountPerc,
            DiscountAmount: detail.DiscountAmount,
            TaxAmount: detail.TaxAmount,
            Amount: detail.Amount,
            MRP: detail.MRP,
            PurchasePrice: detail.PurchasePrice,
            BatchCode: detail.Batchcode,
            MinimumSalesPrice: 100,
            // AverageCost: detail.AverageCost, // Uncomment if needed
            TaxableAmount: detail.TaxableAmount,
            NonTaxableAmount: detail.NonTaxableAmount,
          };
        });
        lineItem = updatedDataList
        let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound,GST,CompanyState)

      
        updatedDataList.push(calculated_lineItem)
        setDataLists(updatedDataList);
      }
  }
    else {
      console.log("EEEEEEEEEEEEERRRRRRRRRRRRRRRRROOOOOORRRRRRRRRRRR",);
    }
  }


  const addByBarCode = async(text) =>{
    
    let Data = DataList;
    let index = Data.length
    let lineItem =  DataListItem
    console.log(lineItem,"lineItem--------------");
    let Product = await Call_Barcode_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      BarCode: text,
    })
    
    if (Product){
      let UnitList = await Call_PriceList_list_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        product_id : Product.id,
        type_data : "SI"             // ------------------------------------------------------------------------------------------------------------------------
      })
      
      let Unit = UnitList.find((i) => i.UnitInSales === true)
      let PriceListID = Unit.PriceListID
      

      let UnitDetails = await Call_Single_PriceList_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        PriceListID:PriceListID,
        PriceRounding:Number(PriceDecimalPoint || 2),
        WarehouseID:state.Warehouse.WarehouseID
      })
      
      let Rate = 0 
      let Qty = 1
      let FQty = 0
      let DiscountPerc = 0
      let DiscountAmount = 0
      
  
      
      if (UnitDetails){
        Rate = Number(UnitDetails.SalesPrice)
        
        lineItem.MRP = UnitDetails.MRP
        lineItem.Rate = Rate
        lineItem.PurchasePrice = UnitDetails.PurchasePrice
        lineItem.BarCode = UnitDetails.BarCode
        lineItem.BatchCode = UnitDetails.BatchCode
        lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice
        // lineItem.AverageCost = UnitDetails.AverageCost
  
      }
      
      let Tax = dataState.TaxCategoryData.find((i) => i.TaxID === Product.ProductTaxID)
  
      lineItem.Product = Product
      lineItem.ProductCode = Product
      lineItem.Description = Product.Description
      lineItem.Stock = Product.Stock
      lineItem.Qty = Qty
      lineItem.FQty = FQty
      lineItem.Tax = Tax
      lineItem.DiscountPerc = DiscountPerc
      lineItem.DiscountAmount = DiscountAmount
  
      lineItem.Unit = Unit
      lineItem.UnitList = UnitList
      
      let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound,GST,CompanyState)
      
      Data.push(calculated_lineItem)
      
      setDataLists([...Data]);
      
    }
    
  }

  const clearData = async () => {
    
    if (is_edit){
      navigate("/create-sales-Return");
    }
    setIsButtonClick(false)
    
    //new voucher number
    let voucher_datas = await get_VoucherNo({
      CompanyID: CompanyID,
      UserID: user_id,
      BranchID: BranchID,
      VoucherType: "SR",
    });
    
    setDataState({
      ...dataState,
      ...voucher_datas,
      BillWiseDatas:[],
    })
  
    setState(({
      ...state,
      AccountLedger: defaultValue.AccountLedger,
      BillingAddress: defaultValue.BillingAddress,
      CashAccount: defaultValue.CashAccount,
      BankAccount: defaultValue.BankAccount,
      // Employee: null,
      Warehouse: defaultValue.Warehouse,
      Treatment: defaultValue.Treatment,
      PlaceOfSupply: defaultValue.PlaceOfSupply,
      Date: DefaultDate,
      RefferenceBillNo: "",
      RefferenceBillDate: DefaultDate,
      BillDiscPercent: 0,
      BillDiscAmt: 0,
      CashAmount: 0,
      BankAmount: 0,
      Notes: "",
      //Total
      TotalQty: 0,
      TotalGrossAmt: 0,
      TotalDiscount: 0,
      TotalTax: 0,
      NetTotal: 0,
      TotalTaxableAmount: 0,
      TaxTaxableAmount: 0,
      NonTaxTaxableAmount: 0,
      GrandTotal: 0,
      LedgerValue: "",
      RoundOff:"0",
    }))
    
    setDataLists([DataListItem,]);
    
  };
  

  const handleSubmit = async (e,IsBillWise = true) => {
    console.log(IsBillwise,";;;;;;;;;;;;;;;;;;;;;;;;",IsBillWise);
    setErrors({
      field : null,
      message : null,
      error : false,
      indexList : [], 
    })
    
    let { error, message, field, indexList } = await SalesReturnValidation(dataState,state,DataList,GeneralSettingsData);
    if (error){
      let errors = {
        error,
        message,
        field,
        indexList,
      };
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "warning",
        })
      );
      setErrors({ ...state, errors });
    } else {
      let Details = [];
      DataList.map((i) => {
        if (i.Product) {
          let item = {
            id : i.id,
            ProductID : i.Product.ProductID,
            Qty : i.Qty,
            UnitPrice : i.Rate,
            InclusivePrice : i.InclusivePrice,
            RateWithTax : i.Rate,
            Description : i.Description ??  '',
            DiscountAmount : i.DiscountAmount,
            GrossAmount : i.GrossAmount,
            TaxableAmount : i.TaxableAmount ,
            TaxAmount : i.TaxAmount,
            NetAmount : i.Amount,
            FreeQty : i.FQty,
            // CostPerPrice : i.Rate,
            PriceListID : i.Unit.PriceListID,
            DiscountPerc : i.DiscountPerc,
            // VATPerc : i.Rate,
            BatchCode : i?.BatchCode?.BatchCode,
            Description : i.Description,
            ProductTaxID : i.Tax.TaxID,
            NonTaxableAmount:i.NonTaxableAmount,
            PurchasePrice:i.PurchasePrice,
            SGSTAmount:i.SGSTAmount,
            CGSTAmount:i.CGSTAmount,
            IGSTAmount:i.IGSTAmount,
            IGSTPerc:i.IGSTPerc,
            CGSTPerc : i.CGSTPerc,
            SGSTPerc:i.SGSTPerc

          }
          Details.push(item)
        }
      })

      let payload = {
        CompanyID : CompanyID,
        BranchID : BranchID,
        CreatedUserID : user_id,
        PriceRounding : PriceDecimalPoint,
        Details : Details,
        is_manual_roundoff : is_manual_roundoff,
        uniq_id:uniq_id,//uniq_id

        RefferenceBillNo : state.RefferenceBillNo,
        RefferenceBillDate : state.RefferenceBillDate,
        // ------
        VoucherDate : state.Date,
        Seperator: dataState.Seperator,
        Suffix: dataState.Suffix,
        SuffixSeparator: dataState.SuffixSeparator,
        PreFix: dataState.PreFix,
        InvoiceNo: dataState.InvoiceNo,
        VoucherNo: dataState.VoucherNo,
        // ---------
        BillwiseDetails : dataState.BillWiseDatas,
        LedgerID : state.AccountLedger.LedgerID,
        // PriceCategoryID:state.PriceCategory.PriceCategoryID,
        SalesAccount : 86,
        EmployeeID :state.Employee?.EmployeeID,
        CustomerName:state.AccountLedger.LedgerName,
        CashID:state.CashAccount.LedgerID,
        BankID:state.BankAccount.LedgerID ?? 92,
        WarehouseID:state.Warehouse.WarehouseID,
        Treatment:state.Treatment.value,
        State_of_Supply:state.PlaceOfSupply.id,
        Country_of_Supply:state.PlaceOfSupply.Country,
        BillDiscPercent:state.BillDiscPercent,
        BillDiscAmt:state.BillDiscAmt,
        CashAmount:state.CashAmount,
        BankAmount:state.BankAmount,
        Notes:state.Notes,
        RoundOff:state.RoundOff,
        // Total
        TotalQty:state.TotalQty,
        TotalGrossAmt : state.TotalGrossAmt,
        TotalDiscount:state.TotalDiscount,
        TotalTax:state.TotalTax,
        NetTotal:state.NetTotal,
        TotalTaxableAmount:state.TotalTaxableAmount,
        TaxTaxableAmount:state.TaxTaxableAmount,
        NonTaxTaxableAmount:state.NonTaxTaxableAmount,
        GrandTotal:state.GrandTotal,
        BillingAddress:state.BillingAddress?.id,
        TotalCGST:state.TotalCGST,
        TotalSGST:state.TotalSGST,
        TotalIGST:state.TotalIGST,
        TaxType : state.TaxType,
        TaxID : state.TaxID,

      }

      let responseData = null
      if (IsBillwise === true && IsBillWise === true) {
        setOpenBillWise(true);
      } else {
        if (is_edit && uniq_id){
          setIsButtonClick (true);
          responseData = await edit_salesreturn_api(payload)
        }
        else{
          setIsButtonClick (true);
          responseData = await CallToSalesreturn(payload)
        }
        if (responseData?.StatusCode === 6000) {
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Created Successfully",
              severity: "success",
            })
          );
          clearData();
        } else if (responseData?.StatusCode === 6001) {
          setIsButtonClick(false)
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "warning",
            })
          );
        } else if (responseData?.StatusCode === 6002) {
          setIsButtonClick(false)
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "error",
            })
          );
        } else {
          setIsButtonClick(false)
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
              severity: "error",
            })
          );
        }
      }
    }
  };

  const calculateData = (dataState, state, DataList, customRound, GST, CompanyState, setDataLists) => {
    const updatedData = DataList.map((lineItem) => {
      const updatedLineItem = SalesLineCalc(dataState, state, lineItem, customRound, GST, CompanyState);
      return updatedLineItem;
    });
    
    setDataLists([...updatedData]);
  };


  //giving a little time waiting for onDetailsInputChange function
  const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if(GST) {
      calculateData(dataState, state, DataList, customRound, GST, CompanyState, setDataLists);
    }
  }, [state.PlaceOfSupply,state.Treatment]);


  useEffect(() => {
    const CalculateData = () => {
      let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff,customRound,GST,CompanyState);
      setState({ ...newState });
    };
    CalculateData();
    console.log('ppppppppppppppppppppppppppppppppppooooooo');
    
  }, [DataList]);


  //=============KEYBOARD SHORTCUTS===================== 
  const shortCutKeyPress = useCallback (async(event) => {
    //sales history
    if (event.altKey && (event.key === 'q' || event.keyCode === 81) && sales_history_view_permission){
      if (DataList.length > 0){        
        let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
        if (ProductIDs.length > 0){              
          let SalesHistoryResponse = await Call_Product_History_api({
            Type:"Sales",
            items_per_page:5,
            page_no:1
          })
          
          setDataState({...dataState,SalesHistory:SalesHistoryResponse})
        }
      }
      setIsSalesHistory(true)
    }
    //purchase history
    else if (event.altKey && (event.key === 'w' || event.keyCode === 87 || event.keyCode === 119) && purchase_history_view_permission){
      if (DataList.length > 0){        
        let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
        if (ProductIDs.length > 0){              
          let PurchaseHistoryResponse = await Call_Product_History_api({
            Type:"Purchase",
            items_per_page:5,
            page_no:1
          })
          
          setDataState({...dataState,PurchaseHistory:PurchaseHistoryResponse})
        }
      }
      setIsPurchaseHistory(true)
    }
    //closing modal
    else{
      setIsSalesHistory(false)
      setIsPurchaseHistory(false)
    }
    
  },[state.AccountLedger,DataList])


  useEffect(() => {
    window.addEventListener('keydown', shortCutKeyPress);
    return () => {
      window.removeEventListener('keydown', shortCutKeyPress);
    };
  }, [shortCutKeyPress ]);
  console.log(tableSettings,"TAABlEEEEEEEEEEEEE");
  
  console.log(state, "state::::::>>>>>>>>>>>>>>>>>>datastateeeeeeee",dataState);
  console.log(DataList,"DatatttttListttttttttttsttststtstt");
  console.log(state.AccountLedger?.LedgerID,"LedgerIDDDD");
  console.log(BillingAddress.BillingAddressList,"BillingAddessListttttttttttttttttttttttttttttttt");
  return (
    <>
      <Paper sx={{ height: "800px", width: "100%", overflow: "scroll" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                mr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {is_edit ? t("Update Sales Return") : t("Create Sales Return")}
            </Typography>
          </FlexBox>

          <FlexBox className="right">
            <ClearButton
              onClick={() => {
                clearData();
              }}
              t={t}
            />

            <ListButton
              onClick={() => {
                navigate("/list-sales-return");
              }}
              t={t}
            />

            {IsButtonClick ? (
              <LoadingButton t={t} />
            ) : (
              <SaveButton onClick={handleSubmit} t={t} />
            )}
          </FlexBox>
        </Box>

        {/* Select Customer Container */}
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            height: "250px",
          }}
        >
          <div class="sales-grid-item ">
            {/* customer search */}
            <InvoiceLedgerSearch name = {"AccountLedger"} label = {"Select Customer"} optionLabel = {"LedgerName"} optionLabel2 = {"LedgerCode"} OptionList = {dataState.AccountLedgerData} Value = {state.AccountLedger}  OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} openBillingAddressModal={openBillingAddressModal} BillingAddress={state.BillingAddress}  is_customer={is_customer} GST={GST}/>
          </div>

          <div class="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display:EnableWarehouse ? "flex":'none',
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Warehouse:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Warehouse"}
                  label={"Select a Warehouse"}
                  optionLabel={"WarehouseName"}
                  List={dataState.WarehouseData}
                  Value={state.Warehouse}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              {!EnableEinvoiceKSA && <>
              
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                {VAT ? "VAT Treatment:" : "GST Treatment:"}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Treatment"}
                  label={"Select a Treatment"}
                  optionLabel={"name"}
                  List={VAT ? VAT_TreatmentData : GST ? GST_TreatmentData : []}
                  Value={state.Treatment}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
              </>}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Place Of Supply:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"PlaceOfSupply"}
                  label={"Select a Place Of Supply"}
                  optionLabel={"Name"}
                  List={dataState.PlaceOfSupplyData}
                  Value={state.PlaceOfSupply}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: EnableSalesManInSales?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Sales Man:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Employee"}
                  label={"Select a Employee"}
                  optionLabel={"FirstName"}
                  List={dataState.EmployeeData}
                  Value={state.Employee}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                From Sales:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceBarCodeTextBox
                  onEnter={AddSalesReturn}
                  // name={"FromSales"}
                  // label={"From Sales"}
                  // value={""}
                  // OnChange={""}
                />
              </Typography>
            </div>
          </div>

          <div class="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Invoice No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceVoucherNoTextBox
                  name={"VoucherNo"}
                  label={"Type a VoucherNo"}
                  Value={dataState.VoucherNo}
                  openCustomVoucher={openCustomVoucher}
                  setCustomVoucher = {setopenCustomVoucher}
                  is_edit = {is_edit}
                />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
            <CustomVoucher 
              open = {openCustomVoucher}
              setCustomVoucher = {setopenCustomVoucher}
              state={dataState}
              handleChange={handleChange}
              CustomVoucherSet = {CustomVoucherSet}
            />

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox
                  disabled={EnableEinvoiceKSA}
                  name={"Date"}
                  label={"Select a Date"}
                  Value={state.Date}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Ref Bill No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  name={"RefferenceBillNo"}
                  label={"Type a RefNo"}
                  Value={state.RefferenceBillNo}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Reference Bill Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox
                  name={"RefferenceBillDate"}
                  label={"Select a Date"}
                  Value={state.RefferenceBillDate}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>
          </div>
        </Box>

        {/* Details Grid */}
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          {/* ==========TABLE========== */}
          <MyGrid
            dataState={dataState}
            state={state}
            DataList={DataList}
            setDataLists={setDataLists}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            focusSettings={focusSettings}
            setFocusSettings={setFocusSettings}
            handleInputChange={onDetailsInputChange}
            handleCompleteChanges={onDetailsAutoChange}
            handleChanges={onDetailsChange}
            AddLine={AddLine}
            RemoveLine = {RemoveLine}
            PriceDecimalPoint={PriceDecimalPoint}
            Errors={Errors}
            debouncedOnDetailsInputChange ={debouncedOnDetailsInputChange}
            ChangeTableCustomization = {ChangeTableCustomization}
            GeneralSettingsData={GeneralSettingsData}

          />
        </Box>

        {/* Detail Bottom Design ---------*/}
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{ cursor: "pointer" }} onClick={AddLine}>
            <span
              style={{
                whiteSpace: "nowrap",
                color: "#0A9EF3",
                margin: 0,
                fontSize: "13px",
              }}
            >
              + Add Line{" "}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px", // Adjust the gap between the span and BarcodeTextBox
              height: "40px",
            }}
          >
            <span style={{ whiteSpace: "nowrap", fontSize: "13px" }}>
              Add By Barcode :{" "}
            </span>
            <InvoiceBarCodeTextBox onEnter={addByBarCode} />
          </div>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          {/* ------------Payment And Notes--------------- */}
          <div>
            {/* -----------CASH PAYMENT---------------- */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Cash Account:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Amount:
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"CashAccount"}
                  label={"Select a CashAccount"}
                  optionLabel={"LedgerName"}
                  List={dataState.CashList}
                  Value={state.CashAccount}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"CashAmount"}
                  label={"Type a CashAmount"}
                  Value={state.CashAmount}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            {/* ---------BANK PAYMENT-------------- */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Bank Account:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Amount:
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"BankAccount"}
                  label={"Select a BankAccount"}
                  optionLabel={"LedgerName"}
                  List={dataState.BankList}
                  Value={state.BankAccount}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BankAmount"}
                  label={"Type a BankAmount"}
                  Value={state.BankAmount}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            {/* -------------------NOTES-------------*/}
            <br></br>
            <NotesTextArea
              value={state.Notes ? state.Notes : ""}
              name={"Notes"}
              placeholder={t("Notes (Optional)")}
              onChange={onMasterChange}
            />
          </div>

          <div>
            <div
              style={{
                display: GeneralSettingsData.EnableEinvoiceKSA ? "none" : "flex",                justifyContent: "space-between",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount(%):
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount:
              </Typography>
            </div>

            <div
              style={{
                display: GeneralSettingsData.EnableEinvoiceKSA ? "none" : "flex",                justifyContent: "space-between",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BillDiscPercent"}
                  label={"Type a BillDiscPercent"}
                  Value={state.BillDiscPercent}
                  OnChange={onMasterChange}
                />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BillDiscAmt"}
                  label={"Type a BillDiscAmt"}
                  Value={state.BillDiscAmt}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>
            <Typography
              style={{ marginTop: "10px" }}
              variant="overline"
              display="block"
              gutterBottom
            >
              {/* <AddToItemsButton /> */}
            </Typography>
          </div>

          {/* TOTAL AMOUNTS AND VALUES */}

          <SummaryDetails state={state} is_manual_roundoff={is_manual_roundoff} setIs_manual_roundoff={setIs_manual_roundoff} onChange={onMasterChange} Vat={VAT} Gst={GST}/>
          </Box>

          <BillwiseModal 
            open = {OpenBillWise}
            handleClose = {setOpenBillWise}
            billwiseDetails_datas = {dataState.BillWiseDatas}
            handleChangeDetails = {handleChangeBillwiseDetails}
            get_from_due_sales = {CheckBillwiseAmount}
          />

        {/* =======BILLING ADDRESS MODAL========= */}
        <AddressModal type={"Billing"}  state={state} setState={setState} open={IsBilling} setOpen={setIsBilling} List = {BillingAddress?.BillingAddressList} name={"Billing Address"} Address={BillingAddress} setAddress={setBillingAddress} Call_States_of_Country_api={Call_States_of_Country_api} api = {Create_BillingAddress} />

                  {/* ============HISTORY MODALS============== */}
        <HistoryModal Type = {"Sales"} heading = {"Sales History"} List = {dataState.SalesHistory} open = {IsSalesHistory} setOpen = {setIsSalesHistory} Call_Product_History_api={Call_Product_History_api} dataState={dataState} setDataState={setDataState} />
        <HistoryModal Type = {"Purchase"}  heading = {"Purchase History"} List = {dataState.PurchaseHistory} open = {IsPurchaseHistory} setOpen = {setIsPurchaseHistory} Call_Product_History_api={Call_Product_History_api} dataState={dataState} setDataState={setDataState}  />
      </Paper>
      <BottomNavigationMenu data={MenuBottomData}/>
    </>
  );
};

export default SalesReturn;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "69%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
