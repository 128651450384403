import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ClearButton,
  ListButton,
  LoadingButton,
  SaveButton,
} from "../../Components/Utils/StyledButtons";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  CustomVoucher,
  InvoiceAutoComplete,
  InvoiceBarCodeTextBox,
  InvoiceDateBox,
  InvoiceLedgerSearch,
  InvoiceTextBox,
  InvoiceVoucherNoTextBox,
} from "../../Components/CommonComponents";
import { openSnackbar } from "../../features/SnackBarslice";
import MyGrid from "./Details";
import { debounce } from "../../Function/comonFunction";
import SummaryDetails from "./SummaryDetails";
import NotesTextArea from "../../Components/Utils/TextArea";
import { useNavigate, useLocation } from "react-router-dom";
import {
  SalesEstimateDetails,
  LedgerDetails,
  SalesEstimateCreate,
  saleEstimate_details,
  EditSalesEstimateList,
  SalesEstimateList,
} from "../../Api/Sales/SalesEstimateApis";
import {
  place_of_supply_api,
  ledger_details_api,
  billwise_datas,
  product_search_new_api,
  get_pricelist_list_new_api,
  get_single_pricelist_new_api,
  get_product_by_barcode_api,
  get_VoucherNo,
} from "../../Api/CommonApi/CommonApis";
import {
  SalesLineCalc,
  SalesInvoiceCalc,
  SalesEstimateValidation,
} from "./functions";
import { CallEmployees } from "../../Api/Reports/CommonReportApi";
import BottomNavigationMenu from "../../Components/Utils/BottomNavigationMenu";

const CreateSaleEstimate = () => {
  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  const uniq_id = location.state?.uniq_id ?? params.get("unq_id");
  const is_edit = location.state?.is_edit ?? params.get("is_edit");

  const [is_manual_roundoff, setIs_manual_roundoff] = useState(false);

  const [t, i18n] = useTranslation("common");
  const [IsButtonClick, setIsButtonClick] = useState(false);
  const {
    PriceDecimalPoint,
    EnableBranch,
    EnableProductBatchWise,
    GST,
    VAT,
    CurrencySymbol,
    PriceCategory,
    EnableSalesManInSales,
    PurchasePriceInSales,
    EnableBillwise,
    show_brand_InsearchSale,
    show_stock_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_productcode_InsearchSale,
    EnableEinvoiceKSA,
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);
  let navigate = useNavigate();
  // let today = new Date();
  // today.setDate(today.getDate());
  // let DefaultDate = today.toISOString().substr(0, 10);
  let today = moment();
  let DefaultDate = today.format("YYYY-MM-DD");
  const [openCustomVoucher, setopenCustomVoucher] = useState(false);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const { Country, State } = useSelector((state) => state.companyDetails);
  const dispatch = useDispatch();

  let MenuBottomData = [
    {
      name: "Sales Estimate",
      image: "../images/icons/Estimate.svg",
      link: "/list-sales-estimate",
    },
    {
      name: "Sales Order",
      image: "../images/icons/Order.svg",
      link: "dashboard/sales-order-list",
      domain: "books",
    },
    {
      name: "Sales Invoice",
      image: "../images/icons/Invoice.svg",
      link: "/list-sales",
    },
    {
      name: "Sales Return",
      image: "../images/icons/Return.svg",
      link: "/list-sales-return",
    },
  ];

  function customRound(x) {
    if (EnableEinvoiceKSA) {
      const rounded = Math.round(x * 100) / 100;

      if (rounded - x === 0.5) {
        return rounded;
      }

      return rounded;
    } else {
      return Number(x);
    }
  }

  const [dataState, setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    deletedData: [],
    AccountLedgerData: [],
    CashList: [],
    BankList: [],
    CountryData: [],
    EmployeeData: [],
    TaxCategoryData: [],
    UserTableData: [],
    WarehouseData: [],
    PlaceOfSupplyData: [],
    PriceCategoryData: [],
    ProductList: [],
    ProductCodeList: [],
    BillWiseDatas: [],
  });

  const [state, setState] = useState({
    AccountLedger: null,
    CashAccount: null,
    BankAccount: null,
    Employee: null,
    Warehouse: null,
    PriceCategory: null,
    Treatment: null,
    PlaceOfSupply: null,
    Date: DefaultDate,
    ReffNo: "",
    RefferenceBillDate: DefaultDate,
    BillDiscPercent: 0,
    BillDiscAmt: 0,
    CashAmount: 0,
    BankAmount: 0,
    Notes: "",
    //Total
    Warehouse: null,
    TotalQty: 0,
    TotalGrossAmt: 0,
    TotalDiscount: 0,
    TotalTax: 0,
    NetTotal: 0,
    TotalTaxableAmount: 0,
    TaxTaxableAmount: 0,
    NonTaxTaxableAmount: 0,
    GrandTotal: 0,
    LedgerValue: "",
    RoundOff: "0",
    VAT_Treatment: "",
  });

  //when you adding any field here add in AddLine and clearData function tooo
  let DataListItem = {
    ProductCode: null,
    BarCode: "",
    Product: null,
    Description: "",
    Unit: null,
    UnitList: [],
    Stock: "",
    Qty: "",
    FQty: "",
    Rate: "",
    AvgCost: "",
    Tax: null,
    InclusivePrice: "",
    GrossAmount: "",
    DiscountPerc: "",
    DiscountAmount: "",
    // ExciseTax: "",
    TaxAmount: "",
    Amount: "",
    MRP: "",
    PurchasePrice: "",
    BatchCode: "",
    MinimumSalesPrice: "",
    AverageCost: "",
    TaxableAmount: 0,
    NonTaxableAmount: 0,
  };
  const [DataList, setDataLists] = useState([DataListItem]);

  const [defaultValue, setDefaultValue] = useState({
    AccountLedger: null,
    CashAccount: null,
    BankAccount: null,
    PriceCategory: null,
    Warehouse: null,
    Treatment: null,
    PlaceOfSupply: null,
    // ShippingTax: null,
  });

  const [Errors, setErrors] = useState({
    field: null,
    message: null,
    error: false,
    indexList: [],
  });
  const MasterCalcFields = [
    "BillDiscPercent",
    "BillDiscAmt",
    "CashAmount",
    "BankAmount",
    "RoundOff",
  ];
  const [OpenBillWise, setOpenBillWise] = useState(false);
  const [IsBillwise, setBillwise] = useState(false);
  const is_customer =
    state.AccountLedger?.AccountGroupUnder !== 9 &&
    state.AccountLedger?.AccountGroupUnder !== 8;

  const [tableSettings, setTableSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: true,
    Product: true,
    Description: true,
    Unit: true,
    Qty: true,
    FQty: true,
    Rate: true,
    Tax: true,
    InclusivePrice: true,
    GrossAmount: true,
    DiscountPerc: true,
    DiscountAmount: true,
    TaxAmount: true,
    Amount: true,
    Activity: true,
  });
  const [focusSettings, setFocusSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: true,
    Product: true,
    Description: true,
    Unit: true,
    Qty: true,
    FQty: true,
    Rate: true,
    Tax: true,
    InclusivePrice: true,
    GrossAmount: true,
    DiscountPerc: true,
    DiscountAmount: true,
    TaxAmount: true,
    Amount: true,
    Activity: true,
  });
  let GeneralSettingsData = {
    EnableSalesManInSales,
    PriceDecimalPoint,
    PurchasePriceInSales,
    EnableBillwise,
    PurchasePriceInSales,
    show_brand_InsearchSale,
    show_stock_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_productcode_InsearchSale,
    PriceCategory,
  };

  const TreatmentData = [
    { value: "0", name: "Registered Business - Regular", is_gst: true },
    { value: "1", name: "Registered Business - Composition", is_gst: true },
    { value: "2", name: "Consumer", is_gst: true, default: true },
    { value: "4", name: "Overseas", is_gst: true },
    { value: "5", name: "Special Economic Zone", is_gst: true },
    { value: "6", name: "Deemed Export", is_gst: true },
    { value: "0", name: "Business to Business(B2B)", is_vat: true },
    {
      value: "1",
      name: "Business to Customer(B2C)",
      is_vat: true,
      default: true,
    },
  ];

  const GST_TreatmentData = TreatmentData.filter((i) => i.is_gst === true);
  const VAT_TreatmentData = TreatmentData.filter((i) => i.is_vat === true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const CustomVoucherSet = (e) => {
    let PreFix = dataState.PreFix;
    let Seperator = dataState.Seperator;
    let InvoiceNo = dataState.InvoiceNo;
    let SuffixSeparator = dataState.SuffixSeparator
      ? dataState.SuffixSeparator
      : "";
    let Suffix = dataState.Suffix ? dataState.Suffix : "";
    let VoucherNo =
      String(PreFix) +
      String(Seperator) +
      String(InvoiceNo) +
      String(SuffixSeparator) +
      String(Suffix);
    setDataState({ ...dataState, VoucherNo: VoucherNo });
    setopenCustomVoucher(false);
  };

  // Change table Custamization
  const ChangeTableCustomization = (type, name) => {
    let value = null;
    if (type === "tableSettings") {
      value = !tableSettings[name];
      setTableSettings({
        ...tableSettings,
        [name]: value,
      });
    } else {
      value = !focusSettings[name];
      setFocusSettings({
        ...focusSettings,
        [name]: value,
      });
    }
  };

  const Call_salesEstimate_details = async (payload) => {
    let data = null;
    const Response = await saleEstimate_details(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  // Fetching Data For MAster
  const fetchData = async () => {
    let ResponseView = null;
    let AccountLedgerData = [];
    let CashList = [];
    let BankList = [];
    let CountryData = [];
    let EmployeeData = [];
    let PriceCategoryData = [];
    let TaxCategoryData = [];
    let UserTableData = [];
    let WarehouseData = [];
    let Seperator = "";
    let Suffix = "";
    let SuffixSeparator = "";
    let PreFix = "";
    let InvoiceNo = "";
    let VoucherNo = "";
    let PlaceOfSupplyData = [];
    let BillWiseDatas = [];
    let Employee = null;
    let ReffNo = "";
    let BillDiscPercent = 0;
    let BillDiscAmt = 0;
    let Notes = "";
    let PriceCategory = null;

    const Data = await SalesEstimateDetails({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
    });

    if (Data.StatusCode === 6000) {
      const PlaceOfSupplyResponse = await place_of_supply_api(Country);
      if (PlaceOfSupplyResponse.StatusCode === 6000) {
        PlaceOfSupplyData = PlaceOfSupplyResponse.data;
      }

      AccountLedgerData = Data.AccountLedgerData;
      let CashAndBankData = Data.CashAndBankData;
      if (CashAndBankData) {
        CashList = CashAndBankData.filter((i) => i.AccountGroupUnder === 9);
        BankList = CashAndBankData.filter((i) => i.AccountGroupUnder === 8);
      }

      CountryData = Data.CountryData;
      EmployeeData = Data.EmployeeData;
      PriceCategoryData = Data.PriceCategoryData;
      TaxCategoryData = Data.TaxCategoryData;
      UserTableData = Data.UserTableData;
      WarehouseData = Data.WarehouseData;

      let VoucherNoGenerateTableData = Data.VoucherNoGenerateTableData;
      Seperator = VoucherNoGenerateTableData.Seperater;
      Suffix = VoucherNoGenerateTableData.Suffix;
      SuffixSeparator = VoucherNoGenerateTableData.SuffixSeparator;
      PreFix = VoucherNoGenerateTableData.PreFix;
      InvoiceNo = VoucherNoGenerateTableData.InvoiceNo;
      VoucherNo = VoucherNoGenerateTableData.VoucherNo;

      //Default Values
      let AccountLedger = AccountLedgerData.find((i) => i.is_default === true);

      let Warehouse = WarehouseData.find(
        (i) => i.WarehouseID === UserTableData.DefaultWarehouse
      );
      let Treatment = VAT
        ? VAT_TreatmentData.find((i) => i.default === true)
        : GST
        ? GST_TreatmentData.find((i) => i.default === true)
        : null;
      let PlaceOfSupply = PlaceOfSupplyData.find((i) => i.id === State);
      PriceCategory = PriceCategoryData.find((i) => (i.PriceCategoryID = 1));
      let CashAccount = CashList[0];
      let BankAccount = BankList[0];
      setDefaultValue((prev) => {
        return {
          ...prev,
          AccountLedger: AccountLedger,
          CashAccount: CashAccount,
          BankAccount: BankAccount,
          PriceCategory: PriceCategory,
          Warehouse: Warehouse,
          Treatment: Treatment,
          PlaceOfSupply: PlaceOfSupply,
          // ShippingTax:ShippingTax,
        };
      });

      if (is_edit && uniq_id) {
        ResponseView = await SalesEstimateList({
          BranchID: BranchID,
          CompanyID: CompanyID,
          uniq_id: uniq_id,
        });
        console.log(ResponseView, "resssspppppppppppppoooooooooooo");
        if (ResponseView) {
          AccountLedger = await Call_LedgerDetials(
            ResponseView.data.AccountLedger
          );
          Treatment = ResponseView.data.Treatment;
          let PlaceOfSupplyName = ResponseView.data.Place_of_Supply;
          PlaceOfSupply = PlaceOfSupplyData.find(
            (i) => i.Name === PlaceOfSupplyName
          );
          ReffNo = ResponseView.data.ReffNo;
          CashAccount = ResponseView.data.CashAccount;
          BankAccount = ResponseView.data.BankAccount;
          BillWiseDatas = ResponseView.data.BillwiseDetails;
          Employee = ResponseView.data.Employee;
          VoucherNo = ResponseView.data.VoucherNo;
          BillDiscPercent = ResponseView.data.BillDiscPercent;
          BillDiscAmt = ResponseView.data.BillDiscAmt;
          Notes = ResponseView.data.Notes;
          PriceCategory = PriceCategoryData.find(
            (i) => i.PriceCategoryID === ResponseView.data.PriceCategoryID
          );
          setIs_manual_roundoff(ResponseView.data.is_manual_roundoff);

          let TotalQty = Number(ResponseView.data.TotalQty);
          let noOfPages = Math.ceil(TotalQty / 100);
          // let noOfPages = 1;
          let data = [];
          for (let i = 1; i <= noOfPages; i++) {
            let DetailsReponse = await Call_salesEstimate_details({
              BranchID: BranchID,
              CompanyID: CompanyID,
              uniq_id: uniq_id,
              page: i,
            });
            console.log(DetailsReponse, "------------------------>>>wdcjgh");
            if (DetailsReponse) {
              data = [...data, ...DetailsReponse];
              console.log(data, "dtaadtf----------jhjgjg");
            }
          }

          console.log(data, "============>>DATATDDTADA");
          setDataLists([...data]);

          // setDataState((prev) => ({
          //   ...prev,
          //   BillWiseDatas: BillWiseDatas,
          // }));

          // console.log(BillWiseDatas,"resssspppppppppppppooooooooooooBillwiseDAtaaaaaaaa",dataState.BillWiseDatas);
        }
      }

      setState((prev) => {
        return {
          ...prev,
          ...ResponseView,
          AccountLedger,
          Warehouse,
          Treatment,
          PlaceOfSupply,
          CashAccount,
          BankAccount,
          PriceCategory,
          ReffNo,
          Employee,
          BillDiscPercent,
          BillDiscAmt,
          Notes,
        };
      });

      setDataState((prev) => {
        return {
          ...prev,
          AccountLedgerData,
          CashList,
          BankList,
          CountryData,
          EmployeeData,
          PriceCategoryData,
          TaxCategoryData,
          UserTableData,
          WarehouseData,
          Seperator,
          Suffix,
          SuffixSeparator,
          PreFix,
          InvoiceNo,
          VoucherNo,
          PlaceOfSupplyData,
          BillWiseDatas,
        };
      });
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Data?.message ?? "get salesreturn Api Error",
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const CalculateData = () => {
      let newState = SalesInvoiceCalc(dataState, state, DataList);
      setState({ ...newState });
    };
    CalculateData();
  }, [DataList]);
  // search employee
  const Call_Employees_api = async (payload) => {
    let data = [];
    const Response = await CallEmployees(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Seles Man Search Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const Call_LedgerDetials = async (data) => {
    console.log(data, "Printing ===============>>>");
    const LedgerDetails = await ledger_details_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      ledger_id: data?.id,
      PriceRounding: PriceDecimalPoint,
    });

    if (LedgerDetails.StatusCode === 6000) {
      let AccountLedger = data; //state.AccountLedger
      AccountLedger["Treatment"] = LedgerDetails.data.Treatment;
      AccountLedger["TaxNo"] = LedgerDetails.data.TaxNo;
      AccountLedger["Mobile"] = LedgerDetails.data.Mobile;
      AccountLedger["BillingAddress"] = LedgerDetails.data.BillingAddress;
      AccountLedger["RouteName"] = LedgerDetails.data.RouteName;
      AccountLedger["Balance"] = LedgerDetails.data.Balance;
      AccountLedger["PriceCategoryID"] = LedgerDetails.data.PriceCategoryID;

      // setState({...state,AccountLedger:AccountLedger})
      Call_BillwiseDetails(AccountLedger);
      return AccountLedger;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: LedgerDetails?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
  };

  const Call_BillwiseDetails = async (data) => {
    try {
      if (!uniq_id && !is_edit) {
        const billWiseDataResponse = await billwise_datas({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          LedgerID: data?.LedgerID ?? 0,
          PaymentVoucherNo: dataState.VoucherNo,
          PaymentVoucherType: "SE",
          PriceRounding: PriceDecimalPoint,
          call_type: "list_type",
        });

        setDataState((prevState) => ({
          ...prevState,
          BillWiseDatas:
            billWiseDataResponse.StatusCode === 6000
              ? billWiseDataResponse.data
              : [],
        }));
        if (billWiseDataResponse?.data?.length) {
          setBillwise(true);
        } else {
          setBillwise(false);
        }
      } else {
        console.log("Passs");
      }
    } catch (error) {
      console.error("Error fetching billwise details:", error);
      setDataState({
        ...dataState,
        BillWiseDatas: [],
      });
    }
  };

  const onMasterChange = (e) => {
    if (e) {
      const { name, value } = e.target;

      //checking the field is included in MasterCalcFields
      const isCalcField = MasterCalcFields.includes(name);

      if (isCalcField) {
        state[name] = value;

        //handling bill disc
        if (name === "BillDiscAmt") {
          state.BillDiscPercent =
            100 * (Number(value) / Number(state.NetTotal));
        } else if (name === "BillDiscPercent") {
          state.BillDiscAmt = (Number(value) / 100) * Number(state.NetTotal);
        }

        let newState = SalesInvoiceCalc(
          dataState,
          state,
          DataList,
          is_manual_roundoff
        );
        setState({ ...newState });
      } else {
        setState((prevState) => ({ ...prevState, [name]: value }));
      }
    }
  };

  const onDetailsInputChange = async (e, name) => {
    if (e && name) {
      let value = e.target.value;
      if ((name === "Product" || name === "ProductCode") && value) {
        const ProductList = await Call_ProductSearchFunction({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state?.Warehouse.WarehouseID,
          is_product_image: false,
          length: value?.length,
          product_name: value,
          type: "SalesEstimate",
        });
        setDataState({ ...dataState, ProductList: ProductList });
      }
    }
  };

  const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);

  const addByBarCode = async (text) => {
    let Data = DataList;
    let index = Data.length;
    let lineItem = DataListItem;
    console.log(lineItem, "lineItem--------------");
    let Product = await Call_Barcode_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      BarCode: text,
    });

    if (Product) {
      let UnitList = await Call_PriceList_list_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        product_id: Product.id,
        type_data: "SI", // ------------------------------------------------------------------------------------------------------------------------
      });

      let Unit = UnitList.find((i) => i.UnitInSales === true);
      let PriceListID = Unit.PriceListID;

      let UnitDetails = await Call_Single_PriceList_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        PriceListID: PriceListID,
        PriceRounding: Number(PriceDecimalPoint || 2),
        WarehouseID: state.Warehouse.WarehouseID,
      });

      let Rate = 0;
      let Qty = 1;
      let FQty = 0;
      let DiscountPerc = 0;
      let DiscountAmount = 0;

      if (UnitDetails) {
        Rate = Number(UnitDetails.Rate);

        lineItem.MRP = UnitDetails.MRP;
        lineItem.Rate = Rate;
        lineItem.PurchasePrice = UnitDetails.PurchasePrice;
        lineItem.BarCode = UnitDetails.BarCode;
        lineItem.BatchCode = UnitDetails.BatchCode;
        lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
        // lineItem.AverageCost = UnitDetails.AverageCost
      }

      let Tax = dataState.TaxCategoryData.find(
        (i) => i.TaxID === Product.ProductTaxID
      );

      lineItem.Product = Product;
      lineItem.ProductCode = Product;
      lineItem.Description = Product.Description;
      lineItem.Stock = Product.Stock;
      lineItem.Qty = Qty;
      lineItem.FQty = FQty;
      lineItem.Tax = Tax;
      lineItem.DiscountPerc = DiscountPerc;
      lineItem.DiscountAmount = DiscountAmount;

      lineItem.Unit = Unit;
      lineItem.UnitList = UnitList;

      let calculated_lineItem = await SalesLineCalc(
        dataState,
        state,
        lineItem,
        customRound
      );

      Data.push(calculated_lineItem);

      setDataLists([...Data]);
    }
  };

  const onDetailsChange = async (e, index) => {
    if (e) {
      let value = e.target.value;
      let name = e.target.name;
      let Data = DataList;
      console.log(
        name,
        value,
        "name,value:LLLLLLLLLLLLLLL:::::::::::::::::::::::::::"
      );
      let lineItem = Data[index];
      lineItem[name] = value;

      // handling Line Discount
      let Amount = lineItem.GrossAmount; //+ lineItem.TaxAmount;
      if (name === "DiscountAmount") {
        lineItem.DiscountPerc = 100 * (Number(value) / Number(Amount));
      } else if (name === "DiscountPerc") {
        lineItem.DiscountAmount = (Number(value) / 100) * Number(Amount);
      }

      let calculated_lineItem = await SalesLineCalc(
        dataState,
        state,
        lineItem,
        customRound
      );

      Data[index] = calculated_lineItem;
      setDataLists([...DataList]);
    }
  };

  const onDetailsAutoChange = async (v, index, name) => {
    console.log(v, name, "333333333");
    if (v && name && v?.id) {
      let Data = DataList;
      let lineItem = Data[index];

      if (name === "Product" || name === "ProductCode") {
        let Product = v;
        let UnitList = await Call_PriceList_list_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          product_id: Product.id,
          type_data: "SI", //______________----------------------------------------------------------------------------------------------------------------------------
        });

        let Unit = UnitList.find((i) => i.UnitInSales === true);
        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.Warehouse.WarehouseID,
        });
        let Rate = 0;
        let Qty = 1;
        let FQty = 0;
        let DiscountPerc = 0;
        let DiscountAmount = 0;

        // if (UnitDetails) {
        //   Rate = Number(UnitDetails.Rate);

        //   lineItem.MRP = UnitDetails.MRP;
        //   lineItem.Rate = Rate;
        //   lineItem.PurchasePrice = UnitDetails.PurchasePrice;
        //   lineItem.BarCode = UnitDetails.BarCode;
        //   lineItem.BatchCode = UnitDetails.BatchCode;
        //   lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
        //   lineItem.AverageCost = UnitDetails.AverageCost;
        // }

        if (UnitDetails) {
          Rate = Number(UnitDetails.Rate);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
          lineItem.is_inclusive = UnitDetails.is_inclusive;
          lineItem.Stock = UnitDetails.Stock;

          lineItem.SalesPrice = UnitDetails.SalesPrice;
          lineItem.SalesPrice1 = UnitDetails.SalesPrice1;
          lineItem.SalesPrice2 = UnitDetails.SalesPrice2;
          lineItem.SalesPrice3 = UnitDetails.SalesPrice3;

          if (state?.PriceCategory?.PriceCategoryID === 1) {
            lineItem.Rate = UnitDetails.SalesPrice;
          } else if (state?.PriceCategory?.PriceCategoryID === 2) {
            lineItem.Rate = UnitDetails.SalesPrice1;
          } else if (state?.PriceCategory?.PriceCategoryID === 3) {
            lineItem.Rate = UnitDetails.SalesPrice2;
          } else if (state?.PriceCategory?.PriceCategoryID === 4) {
            lineItem.Rate = UnitDetails.SalesPrice3;
          }
        }

        let Tax = dataState.TaxCategoryData.find(
          (i) => i.TaxID === Product.ProductTaxID
        );

        lineItem.Product = Product;
        lineItem.ProductCode = Product;
        lineItem.Description = Product.Description;
        lineItem.Stock = Product.Stock;
        lineItem.Qty = Qty;
        lineItem.FQty = FQty;
        lineItem.Tax = Tax;
        lineItem.DiscountPerc = DiscountPerc;
        lineItem.DiscountAmount = DiscountAmount;

        lineItem.Unit = Unit;
        lineItem.UnitList = UnitList;

        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      } else if (name === "Unit") {
        let Unit = v;

        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.Warehouse.WarehouseID,
        });
        let Rate = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.Rate);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
        }
        lineItem.Unit = Unit;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );

        Data[index] = calculated_lineItem;
        setDataLists([...Data]);
      } else if (name === "Tax") {
        let Tax = v;
        lineItem.Tax = Tax;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      }
    }
  };

  const AddLine = () => {
    let Data = DataList;
    let newData = DataListItem;
    Data.push(newData);
    setDataLists([...Data]);
  };

  const RemoveLine = (index) => {
    let Data = DataList;
    let deletedData = [];
    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        if (is_edit) {
          deletedData.push(Data[index]);
        }

        Data.splice(index, 1);
      }
    }
    let newState = SalesInvoiceCalc(dataState, state, Data);
    setState({ ...newState, deletedData: deletedData });

    setDataLists([...Data]);
  };

  const clearData = async () => {
    if (is_edit) {
      navigate("/create-sales-estimate");
    }
    setIsButtonClick(false);

    //new voucher number
    let voucher_datas = await get_VoucherNo({
      CompanyID: CompanyID,
      UserID: user_id,
      BranchID: BranchID,
      VoucherType: "SE",
    });

    setDataState({
      ...dataState,
      ...voucher_datas,
    });

    setState({
      ...state,
      AccountLedger: defaultValue.AccountLedger,
      CashAccount: defaultValue.CashAccount,
      BankAccount: defaultValue.BankAccount,
      PriceCategory: defaultValue.PriceCategory,
      // Employee: null,
      Warehouse: defaultValue.Warehouse,
      Treatment: defaultValue.Treatment,
      PlaceOfSupply: defaultValue.PlaceOfSupply,
      Date: DefaultDate,
      ReffNo: "",
      RefferenceBillDate: DefaultDate,
      BillDiscPercent: 0,
      BillDiscAmt: 0,
      CashAmount: 0,
      BankAmount: 0,
      Notes: "",
      //Total
      TotalQty: 0,
      TotalGrossAmt: 0,
      TotalDiscount: 0,
      TotalTax: 0,
      NetTotal: 0,
      TotalTaxableAmount: 0,
      TaxTaxableAmount: 0,
      NonTaxTaxableAmount: 0,
      GrandTotal: 0,
      LedgerValue: "",
      RoundOff: "0",
    });

    setDataLists([DataListItem]);
  };

  const onMasterInputChange = async (search, name) => {
    if (search && name) {
      let length = search.length;
      if (name === "AccountLedger") {
        const LedgerList = await LedgerDetails({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          ledger_name: search,
          length: length,
          load_data: false,
          type_invoice: "SalesInvoice",
        });
        console.log(LedgerList);
        if (LedgerList.StatusCode === 6000) {
          let AccountLedgerData = [...LedgerList.data];
          setDataState({ ...dataState, AccountLedgerData: AccountLedgerData });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: LedgerList?.message ?? "Api Error",
              severity: "error",
            })
          );
        }
      } else if (name === "Employee") {
        const EmployeeList = await Call_Employees_api({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          search: search,
          list_type: "sales",
        });
        const uniqueSalesmansData = EmployeeList.filter((salesman) => {
          // Check if the salesman id is not already in EmployeesList
          return !dataState.EmployeeData.some(
            (employee) => employee.id === salesman.id
          );
        });
        let EmployeeData = [...dataState.EmployeeData, ...uniqueSalesmansData];
        setDataState({ ...dataState, EmployeeData: EmployeeData });
      }
    }
  };

  const onMasterAutoChange = async (e, v, name) => {
    if (v && name) {
      const isCalcField = MasterCalcFields.includes(name);
      let value = v;
      if (name === "AccountLedger") {
        let data = await Call_LedgerDetials(value);
        let Treatment = VAT_TreatmentData.find(
          (i) => i.value === data.Treatment
        );

        let PriceCategory = dataState.PriceCategoryData.find(
          (i) => i.PriceCategoryID === 1
        );
        if (data.PriceCategoryID) {
          PriceCategory = dataState.PriceCategoryData.find(
            (i) => i.PriceCategoryID === data.PriceCategoryID
          );
        }

        setState({
          ...state,
          [name]: data,
          Treatment,
          ShippingAddress: null,
          BillingAddress: null,
          PriceCategory,
        });
      } else if (name === "PriceCategory") {
        let Data = DataList;
        Data.map((lineItem, index) => {
          if (value?.PriceCategoryID === 1) {
            lineItem.Rate = lineItem.SalesPrice;
          } else if (value?.PriceCategoryID === 2) {
            lineItem.Rate = lineItem.SalesPrice1;
          } else if (value?.PriceCategoryID === 3) {
            lineItem.Rate = lineItem.SalesPrice2;
          } else if (value?.PriceCategoryID === 4) {
            lineItem.Rate = lineItem.SalesPrice3;
          }

          let calculated_lineItem = SalesLineCalc(
            dataState,
            state,
            lineItem,
            customRound
          );
          Data[index] = calculated_lineItem;
        });

        setDataLists([...Data]);
        setState({ ...state, [name]: value });
      }

      // else if (name === "Warehouse"){
      //   let Data = DataList
      //   let payload = DataList.map(object => object?.Unit?.PriceListID);
      //   let StockData = await Call_Stock_api({
      //     CompanyID: CompanyID,
      //     BranchID: BranchID,
      //     PriceListIDList : payload ,
      //     WarehouseID:value.WarehouseID
      //   })

      //   Data.map((lineItem,index)=>{
      //     if (lineItem.Product){
      //       lineItem.Stock =  StockData.find((i)=> i.PriceListID === lineItem.Unit.PriceListID)?.Stock ?? 0
      //       Data[index] = lineItem
      //     }
      //   })

      //   setDataLists([...Data]);
      //   setState({...state,[name]:value})

      // }
      else if (isCalcField) {
        state[name] = value;
        let newState = SalesInvoiceCalc(
          dataState,
          state,
          DataList,
          is_manual_roundoff
        );
        setState({ ...newState });
      } else {
        setState({ ...state, [name]: value });
      }
    }
  };

  const Call_ProductSearchFunction = async (payload) => {
    let data = [];
    const Response = await product_search_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ? Response.message : "API error",
          severity: "error",
        })
      );
    }
    return data;
  };

  const Call_PriceList_list_Function = async (payload) => {
    let data = [];
    const Response = await get_pricelist_list_new_api(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };
  const Call_Barcode_api = async (payload) => {
    let data = null;
    const Response = await get_product_by_barcode_api(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  //Detials of selected unit(pricelist) api
  const Call_Single_PriceList_Function = async (payload) => {
    let data = null;
    const Response = await get_single_pricelist_new_api(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const handleSubmit = async (e, IsBillWise = true) => {
    console.log(IsBillwise, ";;;;;;;;;;;;;;;;;;;;;;;;", IsBillWise);
    setErrors({
      field: null,
      message: null,
      error: false,
      indexList: [],
    });

    let { error, message, field, indexList } = await SalesEstimateValidation(
      dataState,
      state,
      DataList,
      GeneralSettingsData
    );
    console.log(error, "errrrrrrrrrrrrrrrrrorrrorooroooooo");
    if (error) {
      let errors = {
        error,
        message,
        field,
        indexList,
      };
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "warning",
        })
      );
      setErrors({ ...state, errors });
    } else {
      let Details = [];
      DataList.map((i) => {
        if (i.Product) {
          let item = {
            id: i.id,
            ProductID: i.Product.ProductID,
            Qty: i.Qty,
            UnitPrice: i.Rate,
            InclusivePrice: i.InclusivePrice,
            RateWithTax: i.Rate,
            Description: i.Description ?? "",
            DiscountAmount: i.DiscountAmount,
            GrossAmount: i.GrossAmount,
            TaxableAmount: i.TaxableAmount,
            TaxAmount: i.TaxAmount,
            NetAmount: i.Amount,
            FreeQty: i.FQty,
            Tax: i.Tax,
            // CostPerPrice : i.Rate,
            PriceListID: i.Unit.PriceListID,
            DiscountPerc: i.DiscountPerc,
            // VATPerc : i.Rate,
            BatchCode: i.BatchCode,
            Description: i.Description,
            ProductTaxID: i.Tax.TaxID,
            NonTaxableAmount: i.NonTaxableAmount,
            PurchasePrice: i.PurchasePrice,
          };
          Details.push(item);
        }
      });

      let payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        PriceRounding: PriceDecimalPoint,
        Details: Details,
        is_manual_roundoff: is_manual_roundoff,
        uniq_id: uniq_id, //uniq_id

        ReffNo: state.ReffNo,
        DeliveryDate: state.RefferenceBillDate,
        // ------
        Date: state.Date,
        Seperator: dataState.Seperator,
        Suffix: dataState.Suffix,
        SuffixSeparator: dataState.SuffixSeparator,
        PreFix: dataState.PreFix,
        InvoiceNo: dataState.InvoiceNo,
        VoucherNo: dataState.VoucherNo,
        // ---------
        BillwiseDetails: dataState.BillWiseDatas,
        LedgerID: state.AccountLedger.LedgerID,
        PriceCategoryID: state.PriceCategory.PriceCategoryID,
        SalesAccount: 86,
        EmployeeID: state.Employee?.EmployeeID,
        CustomerName: state.AccountLedger.LedgerName,
        Treatment: state.Treatment.value,
        State_of_Supply: state.PlaceOfSupply.id,
        Country_of_Supply: state.PlaceOfSupply.Country,
        BillDiscPercent: state.BillDiscPercent,
        BillDiscAmt: state.BillDiscAmt,
        CashAmount: state.CashAmount,
        BankAmount: state.BankAmount,
        Notes: state.Notes,
        RoundOff: state.RoundOff,
        VAT_Treatment: state.VAT_Treatment,
        WarehouseID: state.Warehouse.WarehouseID,

        // Total
        TotalQty: state.TotalQty,
        TotalGrossAmt: state.TotalGrossAmt,
        TotalDiscount: state.TotalDiscount,
        TotalTax: state.TotalTax,
        NetTotal: state.NetTotal,
        TotalTaxableAmount: state.TotalTaxableAmount,
        TaxTaxableAmount: state.TaxTaxableAmount,
        NonTaxTaxableAmount: state.NonTaxTaxableAmount,
        GrandTotal: state.GrandTotal,
      };

      let responseData = null;
      if (IsBillwise === true && IsBillWise === true) {
        setOpenBillWise(true);
      } else {
        if (is_edit && uniq_id) {
          setIsButtonClick(true);
          responseData = await EditSalesEstimateList(payload);
        } else {
          setIsButtonClick(true);
          responseData = await SalesEstimateCreate(payload);
        }
        if (responseData?.StatusCode === 6000) {
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Created Successfully",
              severity: "success",
            })
          );
          clearData();
        } else if (responseData?.StatusCode === 6001) {
          setIsButtonClick(false);
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "warning",
            })
          );
        } else if (responseData?.StatusCode === 6002) {
          setIsButtonClick(false);
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "error",
            })
          );
        } else {
          setIsButtonClick(false);
          dispatch(
            openSnackbar({
              open: true,
              message:
                responseData?.message + " : " + responseData?.error ??
                "Something went wrong!",
              severity: "error",
            })
          );
        }
      }
    }
  };

  console.log(DataList, "rthrthtrh");
  console.log(dataState, "rthrthtrh-----------rewafoin");
  console.log(state.Warehouse, "bihawf----Ware house");

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%", overflow: "scroll" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                cmr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {is_edit
                ? t("Update Sales Estimate")
                : t("Create Sales Estimate")}
            </Typography>
          </FlexBox>
          <FlexBox className="right">
            <ClearButton
              onClick={() => {
                clearData();
              }}
              t={t}
            />

            <ListButton
              onClick={() => {
                navigate("/list-sales-estimate");
              }}
              t={t}
            />

            {IsButtonClick ? (
              <LoadingButton t={t} />
            ) : (
              <SaveButton onClick={handleSubmit} t={t} />
            )}
          </FlexBox>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            height: "250px",
          }}
        >
          <div class="sales-grid-item ">
            {/* customer search */}
            <InvoiceLedgerSearch
              name={"AccountLedger"}
              label={"Select Customer"}
              optionLabel={"LedgerName"}
              optionLabel2={"LedgerCode"}
              OptionList={dataState.AccountLedgerData}
              Value={state.AccountLedger}
              OnChange={onMasterAutoChange}
              OnInputChange={onMasterInputChange}
              is_customer={is_customer}
            />
          </div>

          <div class="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                VAT Treatment:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Treatment"}
                  label={"Select a Treatment"}
                  optionLabel={"name"}
                  List={VAT ? VAT_TreatmentData : GST ? GST_TreatmentData : []}
                  Value={state.Treatment}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>
            <div
              style={{
                display: PriceCategory ? "flex" : "none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Price Category:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"PriceCategory"}
                  label={"Select a Price Category"}
                  optionLabel={"PriceCategoryName"}
                  List={dataState.PriceCategoryData}
                  Value={state.PriceCategory}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Place Of Supply:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"PlaceOfSupply"}
                  label={"Select a Place Of Supply"}
                  optionLabel={"Name"}
                  List={dataState.PlaceOfSupplyData}
                  Value={state.PlaceOfSupply}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>

            {/* <div
              style={{
                display: EnableSalesManInSales ? "flex" : "none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Sales Man:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Employee"}
                  label={"Select a Employee"}
                  optionLabel={"FirstName"}
                  // List={dataState.EmployeeData}
                  // Value={state.Employee}
                  // OnChange={onMasterAutoChange}
                  // OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Ref No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  name={"ReffNo"}
                  label={"Type a RefNo"}
                  placeholder={"Ref No"}
                  Value={state.ReffNo}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>
          </div>

          <div class="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Invoice No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceVoucherNoTextBox
                  name={"VoucherNo"}
                  label={"Type a VoucherNo"}
                  is_edit={is_edit}
                  Value={dataState.VoucherNo}
                  openCustomVoucher={openCustomVoucher}
                  setCustomVoucher={setopenCustomVoucher}
                />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <CustomVoucher
                open={openCustomVoucher}
                setCustomVoucher={setopenCustomVoucher}
                state={dataState}
                handleChange={handleChange}
                CustomVoucherSet={CustomVoucherSet}
              />

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox
                  name={"Date"}
                  label={"Select a Date"}
                  Value={state.Date}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Delivery Date:{" "}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox
                  name={"RefferenceBillDate"}
                  label={"Select a Date"}
                  Value={state.RefferenceBillDate}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: EnableSalesManInSales ? "flex" : "none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Sales Man:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Employee"}
                  label={"Select a Employee"}
                  optionLabel={"FirstName"}
                  placeholder={"Select Sales Man"}
                  List={dataState.EmployeeData}
                  Value={state.Employee}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>
          </div>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <MyGrid
            dataState={dataState}
            state={state}
            DataList={DataList}
            setDataLists={setDataLists}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            focusSettings={focusSettings}
            setFocusSettings={setFocusSettings}
            handleInputChange={onDetailsInputChange}
            handleCompleteChanges={onDetailsAutoChange}
            handleChanges={onDetailsChange}
            AddLine={AddLine}
            RemoveLine={RemoveLine}
            PriceDecimalPoint={PriceDecimalPoint}
            Errors={Errors}
            debouncedOnDetailsInputChange={debouncedOnDetailsInputChange}
            ChangeTableCustomization={ChangeTableCustomization}
            GeneralSettingsData={GeneralSettingsData}
          />
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{ cursor: "pointer" }} onClick={AddLine}>
            <span
              style={{
                whiteSpace: "nowrap",
                color: "#0A9EF3",
                margin: 0,
                fontSize: "13px",
              }}
            >
              + Add Line{" "}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px", // Adjust the gap between the span and BarcodeTextBox
              height: "40px",
            }}
          >
            <span style={{ whiteSpace: "nowrap", fontSize: "13px" }}>
              Add By Barcode :{" "}
            </span>
            <InvoiceBarCodeTextBox
              onEnter={addByBarCode}
              placeholder={" Add By Barcode"}
            />
          </div>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          {/* ------------Payment And Notes--------------- */}
          <div>
            <NotesTextArea
              value={state.Notes ? state.Notes : ""}
              name={"Notes"}
              placeholder={t("Notes (Optional)")}
              onChange={onMasterChange}
            />
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount(%):
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount:
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BillDiscPercent"}
                  label={"Type a BillDiscPercent"}
                  Value={state.BillDiscPercent}
                  OnChange={onMasterChange}
                />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BillDiscAmt"}
                  label={"Type a BillDiscAmt"}
                  Value={state.BillDiscAmt}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>
            <Typography
              style={{ marginTop: "10px" }}
              variant="overline"
              display="block"
              gutterBottom
            >
              {/* <AddToItemsButton /> */}
            </Typography>
          </div>

          {/* TOTAL AMOUNTS AND VALUES */}

          <SummaryDetails
            state={state}
            is_manual_roundoff={is_manual_roundoff}
            setIs_manual_roundoff={setIs_manual_roundoff}
            onChange={onMasterChange}
          />
        </Box>
      </Paper>
      <BottomNavigationMenu data={MenuBottomData}/>
    </>
  );
};

export default CreateSaleEstimate;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "69%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
